import * as actionTypes from "../actions/actionTypes";
import {
  productsInitialState,
  getproductsStart,
  getproductsSuccess,
  getproductsFail,
  addItemsToCart,
  emptyCart,
  addVendorId,
  setReload,
} from "./productReducer";

const initialState = {
  ...productsInitialState,
  menuReload: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS_START:
      return getproductsStart(state, action);
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return getproductsSuccess(state, action);
    case actionTypes.GET_PRODUCTS_FAIL:
      return getproductsFail(state, action);
    case actionTypes.ADD_ITEMS_CART_ACTION:
      return addItemsToCart(state, action);
    case actionTypes.EMPTY_CART_ACTION:
      return emptyCart(state, action);
    case actionTypes.ADD_VENDOR_ID:
      return addVendorId(state, action);
    case actionTypes.MENU_RELOAD:
      return setReload(state, action);
    default:
      return state;
  }
};

export default reducer;
