import { useMemo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import Constants from "../../constants";

import "./PaymentGateway.scss";

const getWalletOptions = (paymentRequest: any) => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "light",
          height: "55px",
        },
      },
    }),
    [paymentRequest]
  );

  return options;
};

const usePaymentRequest = ({
  options,
  onPaymentMethod,
  setShowPayLoader,
}: any) => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (stripe && elements && paymentRequest === null) {
      const pr = stripe.paymentRequest(options);
      setPaymentRequest(pr);
    }
  }, [stripe, elements, options, paymentRequest]);

  useEffect(() => {
    setShowPayLoader(true);
    if (paymentRequest) {
      paymentRequest
        .canMakePayment()
        .then((res: any) => {
          if (res) {
            setCanMakePayment(true);
            setShowPayLoader(false);
          } else {
            setShowPayLoader(false);
          }
        })
        .catch((err: any) => {
          setShowPayLoader(false);
        });
    }
    return;
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

export const WalletPayment = ({
  basket,
  placeOrder,
  setShowPayLoader,
}: any) => {
  const stripe = useStripe();
  const history = useHistory();
  let isZeroDecimalCountry = false;
  if (basket.currency) {
    isZeroDecimalCountry = basket.currency in Constants.ZERO_DECIMAL_COUNTRIES;
  }
  const paymentRequest = usePaymentRequest({
    options: {
      country: "GB", // stripe account country code
      currency: basket.currency,
      total: {
        label: "NOQ Events Ltd",
        amount: isZeroDecimalCountry
          ? basket.totalAmount
          : (basket.totalAmount * 100) | 0,
      },
    },
    onPaymentMethod: ({ complete, paymentMethod, ...data }: any) => {
      const orderIdPromise = placeOrder(paymentMethod, stripe, complete);
      orderIdPromise.then((order_id: any) => {
        if (order_id) {
          history.replace(`/orders/${order_id}`);
          return;
        }
      });
    },
    setShowPayLoader,
  });

  const options = getWalletOptions(paymentRequest);

  return (
    paymentRequest && (
      <PaymentRequestButtonElement
        className="wallet_payment"
        options={options}
        onClick={(event: any) => {}}
      />
    )
  );
};
