import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ReactLoading from "react-loading";

import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";
import { notify } from "../../utils/utils";

import "./Zones.scss";

function Zones(props: any) {
  const history = useHistory();

  const [zonesList, setZones] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    let apiEndpoint = "/vendor-zones";

    CommonApiAxios("GET", apiEndpoint, {
      page: 0,
      rowsPerPage: 10,
      eventId: props.match.params.eventId,
    })
      ?.then((resp) => {
        setZones(resp?.data.data.VendorZones);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [props]);

  function displayList() {
    return (
      <div className="zones-list">
        {zonesList.map((zone: any, index: any) => (
          <div
            className="zone-button"
            onClick={() => {
              localStorage.setItem("vendorZone", JSON.stringify(zone));
              history.push({
                pathname: "/events/" + props.match.params.eventId + "/vendors",
                state: zone._id,
              });
            }}
            key={zone._id}
          >
            <h2 style={{ margin: 0 }}>{zone.name}</h2>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      {error ? notify(error) : null}
      <div className="zones">
        <div className="zones-nav">
          <h2>Select Area</h2>
        </div>
        <div className="zones-container">
          <h5 className="h-text">Please select the area where you are</h5>
          {isLoading ? (
            <div className="loading">
              <ReactLoading
                type={"bars"}
                color={"#7422c5"}
                height={100}
                width={100}
              />
            </div>
          ) : (
            displayList()
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    basket: state.cart,
  };
};

export default connect(mapStateToProps, null)(Zones);
