import { useHistory } from "react-router-dom";

export default function NotFoundComponent() {
  const history = useHistory();
  return (
    <div
      role="alert"
      style={{
        backgroundColor: "#fff",
        height: "inherit",
        padding: "5Vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "grey" }}>404</h1>
      <h1 style={{ color: "grey" }}>Page Not Found</h1>
      <div
        style={{
          backgroundColor: "#7422c5",
          color: "white",
          width: "150px",
          padding: "15px",
          margin: "20px",
        }}
        onClick={() => {
          history.goBack();
        }}
      >
        Click here to go back
      </div>
    </div>
  );
}
