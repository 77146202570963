const Constants = {
  serverURL: process.env.REACT_APP_SERVER_URL,
  imageURL: process.env.REACT_APP_IMAGE_URL,
  stripeKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,

  /* For below countries, provide an INTEGER amount as-is 
  i.e without multiplying with the unit conversion factor (100 in case of USD) */
  ZERO_DECIMAL_COUNTRIES: [
    "bif",
    "clp",
    "djf",
    "gnf",
    "jpy",
    "kmf",
    "krw",
    "mga",
    "pyg",
    "rwf",
    "ugx",
    "vnd",
    "vuv",
    "xaf",
    "xof",
    "xpf",
  ],
  GET: "GET",
  POST: "POST",
  VAT_LIST: {
    "5": 21,
    "12.5": 9,
    "20": 6,
  }
};

export default Constants;
