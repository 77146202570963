export const GET_PRODUCTS_START = "GET_PRODUCTS_START";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const ADD_TO_BASKET = "ADD_TO_BASKET";
export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET";

export const GET_USER_CART = "GET USER CART";
export const ADD_ITEMS_CART_ACTION = "ADD_ITEMS_CART_ACTION";
export const EMPTY_CART_ACTION = "EMPTY_CART_ACTION";
export const ADD_VENDOR_ID = "ADD_VENDOR_ID";

export const MENU_RELOAD = "MENU_RELOAD";
