import axios from "axios";
import Constants from "../constants";

export function CommonApiAxios(method, url, params) {
  switch (method) {
    case Constants.GET:
      return axios
        .get(Constants.serverURL + url, { params: params })
        .then((response) => {
          if (response.status == 200) {
            return response;
          }
        })
        .catch((error) => {
          throw new Error(error.message);
        });

    case Constants.POST:
      return axios
        .post(Constants.serverURL + url, params)
        .then((response) => {
          if (response.status == 200) {
            return response;
          }
        })
        .catch((error) => {
          throw new Error(error.message);
        });
  }
}
