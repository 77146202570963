export const PRIMARY_COLOR = "#6020B2";
export const SECONDARY_COLOR = "#EC6BF9";
export const WHITE = "#ffffff";
export const BLACK = "#111111";
export const YELLOW = "#FCC40D";
export const LIGHT_GREEN = "#758D4F";
export const GREEN = "#4CD964";
export const RED = "#FF0000";
export const DARK_ORANGE = "#DA9C55";
export const GRAY = "#8A98BA";
export const LIGHT_GRAY = "#6E7FAA";
export const PINK = "#CD5FDC";
export const PURPLE = "#742BD0";
export const SNOW = "#F9F9F9";
export const LIGHT_PURPLE = "#9132E2";
export const ICON_PURPLE = "#8A3ADD";
export const LIGHT_PINK = "#E355DF";
export const NAVY_BLUE = "#6020B2";
export const TRANSPARENT = "#00000000";
export const GREY = "#CFCBCB";
export const LIGHT_BLUE = "#3E3F68";
export const SCREEN_BACKGROUND = "#FFFFFF";
export const DARK_BLACK="#000000";
export const TEXT_TITLE = "#222455";
export const ORANGE = "#FF5673";
export const SKY_BLUE = "#848DFF";
export const LIGHT_ORANGE = "#FF8C48";
export const BORDER = "#707070";
export const LOCATION_PURPLE="#752AD3";


export const GRAY_TEXT_COLOR = "#a8a8a8";
export const PLACE_HOLDER = "#85808D";


export const SEARCH = "#999999";

export const BLACK_OPAQUE = "rgba(0,0,0,0.6)";

export const DARK_GREEN = "#8558C1";


export const DARK_PINK = "#de42e2";

export const QUANTITY_ROUND_CORNER = "rgba(220,225,231,0.5)";

export const DARK_GREY = "#3b3b3b";
export const REGULAR_GREY = "#808080";

export const LIGHT_GREEN_NEW = "#22b240";

export const LIGHT_GREY = "#d3d4d6";


export const STAR_COLOR = "#f6b06b";
export const PINK_TEXT_COLOR = "#e44c84";
export const LIGHT_STATUS_PURPLE = "#ece8f6";
export const LIGHT_STATUS_PINK = "#fddbe5";

export const RECEIPT_BG = "#F6F6F6";

export const SHADOW_COLOR = "rgba(0, 0, 0, 0.1)";