import { useHistory } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import { getBannerText, notify } from "../../utils/utils";

export default function Card(props: any) {
  const {
    product: { name, price, description, tags, _id },
    vendor,
    inBasketCount,
    vendorStatus,
    showBasket,
  } = props;
  const history = useHistory();
  const inBasket = inBasketCount[_id];
  return (
    <div
      onClick={() => {
        if (vendorStatus === "OPEN") {
          history.push({
            pathname: "/product-detail",
            state: { ...props },
          });
        }else{
          const banner = getBannerText(vendor ? vendor.restaurant : null);
          notify(banner.text)
        }
      }}
      style={{
        backgroundColor: "#fafafa",
        padding: "20px 20px",
        marginBottom: "20px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ fontFamily: "EuropaLight", marginBottom: "10px" }}>
        {name}
      </div>
      {description && (
        <p
          style={{
            color: "#a1a1a1",
            fontFamily: "EuropaLight",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0px",
            marginTop: "-5px",
            marginBottom: "10px",
          }}
        >
          {description}
        </p>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "#f193f8",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <span style={{ fontSize: "18px" }}>
            <FormattedNumber
              value={price}
              style="currency"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              currency={
                vendor && vendor.restaurant ? vendor.restaurant.currency : "gbp"
              }
            />
          </span>
          {tags &&
            tags.length > 0 &&
            tags.map((tag: any, index: any) => (
              <span
                style={{
                  color: "#7422c5",
                  fontFamily: "EuropaLight",
                  fontSize: "14px",
                }}
                key={tag}
              >
                {index === 0 ? " " : ""}
                {tag}
                {index < tags.length - 1 ? "," : <></>}{" "}
              </span>
            ))}
        </div>
        {showBasket && inBasket >= 1 && vendorStatus === "OPEN" && (
          <div
            style={{
              color: "#601fb2",
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              justifySelf: "right",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                textAlign: "right",
                whiteSpace: "nowrap",
              }}
            >
              In basket
            </div>
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: "#601fb2",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "8px",
              }}
            >
              {inBasket}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
