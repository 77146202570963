import { useEffect, useState, Fragment } from "react";
import { Link, useHistory  } from "react-router-dom";
import { connect } from "react-redux";
import ReactLoading from "react-loading";

import Card from "./Card";
import { getProducts, setReload } from "../../store/actions/productActions";
import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";
import {
  getBannerText,
  notify,
  getInBasketCount,
  canShowBasket,
} from "../../utils/utils";

import "./ProductList.scss";
import { ReactComponent as CartBag } from "../../assets/imgs/cartbag.svg";
import { ReactComponent as BackArrow } from "../../assets/imgs/left-arrow.svg";


function ProductList(props: any) {
  const {
    products,
    loadingProducts,
    productError,
    getProducts,
    basket,
    menuReload,
    setMenuReload,
  } = props;
  const vendorId = props.match.params.vendorId;
  const [currentCategory, setCategory] = useState("");
  const [vendor, setvendor] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [showBasket, setShowBasket] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const inBasketCount = getInBasketCount(basket);
  const history = useHistory();

  useEffect(() => {
    if (menuReload || products.length === 0) {
      getProducts(vendorId);
    }
    setMenuReload(true);
    let vendorApiEndpoint = "";
    const paramSearch = new URLSearchParams(props.location.search);
    const eventId = paramSearch.get("eventId");
    const venueId = paramSearch.get("venueId");

    if (eventId) {
      vendorApiEndpoint = "/events/" + eventId + "/hotlink?vendor=" + vendorId;
      setShowBasket(canShowBasket(eventId, null, vendorId, basket));
    } else {
      vendorApiEndpoint = "/venues/" + venueId + "/hotlink?vendor=" + vendorId;
      setShowBasket(canShowBasket(null, venueId, vendorId, basket));
    }

    CommonApiAxios("POST", vendorApiEndpoint, {})
      ?.then((resp) => {
        setvendor(resp?.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [getProducts, vendorId, props.location.search]);

  if (!loadingProducts && !currentCategory && products.length > 0) {
    setCategory(products[0].name);
  }

  function scrollHandler(event: any) {
    if (!loadingProducts) {
      for (let i = 0; i < products.length; i++) {
        let categoryElement = document.getElementById(products[i].name.trim());
        if (categoryElement) {
          if (
            categoryElement.getBoundingClientRect().top < 220 &&
            categoryElement.getBoundingClientRect().bottom > 220
          ) {
            setCategory(products[i].name.trim());
            const navEl = document.getElementById(
              products[i].name.trim() + "-nav"
            );
            if (navEl) {
              navEl.scrollIntoView({
                behavior: "auto",
                block: "end",
              });
            }
            break;
          }
        }
      }
    }
  }
  const el = document.querySelector(".productlist-container");
  if (el) {
    // el.addEventListener("wheel", scrollHandler);
    // el.addEventListener("touchmove", scrollHandler);
    // el.addEventListener("touchend", scrollHandler);
    el.addEventListener("scroll", scrollHandler);
  }
  const banner = getBannerText(
      vendor ? vendor.restaurant : null,
  );

  console.log(vendor)

  return (
    <>
      {productError || error ? notify(error || productError) : null}
      <div className="productlist">
        <div className="productlist-nav">
          <div className="product-detail-nav-top">
            <BackArrow
              onClick={() => {
                history.goBack();
              }}
              className="checkout-back"
              height="30"
              width="30"
            />
            {basket &&
            basket.items &&
            basket.items.length &&
            showBasket &&
            banner && banner.status === "OPEN" ? (
              <Link className="zones-menu" to={{ pathname: "/my-basket" }}>
                <h4 className="zones-link-text">Go Basket</h4>
                <CartBag height="25" width="25" />
                <span className="zones-badge">
                  {basket && basket.items ? basket.items.length : "0"}
                </span>
              </Link>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px"
            }}
          >
            <div>
              <h2 className="productlist-nav-heading">
                {vendor && vendor.restaurant && vendor.restaurant.displayName ? 
                vendor.restaurant.displayName : 
                ""
                }
              </h2>
            </div>
          </div>
          <div className="productlist-nav-sub-heading">
            {!loadingProducts &&
              products.map((category: any, index: any) => (
                <Fragment key={category.id}>
                  <button
                    id={category.name.trim() + "-nav"}
                    className={
                      category.name.trim() === currentCategory
                        ? "menu menu-active"
                        : "menu"
                    }
                    onClick={() => {
                      let element = document.getElementById(
                        category.name.trim()
                      );
                      if (element) {
                        el?.removeEventListener("scroll", scrollHandler);
                        element.scrollIntoView({
                          behavior: "auto",
                          block: "start",
                        });
                        setTimeout(() => setCategory(category.name.trim()), 10);
                      }
                    }}
                  >
                    {category.name}
                  </button>
                </Fragment>
              ))}
          </div>
        </div>
        <div className="productlist-container">
          {loadingProducts || isLoading ? (
            <div className="loading">
              <ReactLoading
                type={"bars"}
                color={"#7422c5"}
                height={100}
                width={100}
              />
            </div>
          ) : (
            <>
              {banner && Object.keys(banner).length > 0 && (
                <p style={{ color: banner.textColor, marginBottom: "0px" }}>
                  {banner.text}
                </p>
              )}
              {products.length === 0 && <h4>The menu is empty.</h4>}
              {products.map((category: any, index: any) => (
                <Fragment key={category.id}>
                  <div id={category.name.trim()}>
                    <h2>{category.name}</h2>
                    <div style={{ marginBottom: "0px" }}>
                      {category.menuItems.map((product: any, index: any) => (
                        <Card
                          key={product._id}
                          product={product}
                          vendor={vendor}
                          inBasketCount={inBasketCount}
                          vendorStatus={banner.status}
                          showBasket={showBasket}
                        />
                      ))}
                    </div>
                  </div>
                </Fragment>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    products: state.products,
    loadingProducts: state.loadingProducts,
    productError: state.productError,
    basket: state.cart,
    prevVendorId: state.vendorId,
    menuReload: state.menuReload,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProducts: (vendorId: any) => {
      dispatch(getProducts(vendorId));
    },
    setMenuReload: (reload: boolean) => {
      dispatch(setReload(reload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
