import { useState, useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import detectOS from "@bit/tomlandau.simple-js.platform-detection.detect-os";

import Landing from "./Landing";
import NotFoundComponent from "../ErrorComponent/NotFoundComponent";
import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";
import {
  notify,
  redirectAppStore,
  continueBrowserUrl,
} from "../../utils/utils";

import "./Landing.scss";

export default function Venue(props: any) {
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(true);

  const query = queryString.parse(props.location.search);
  const vendorId = query.vendor ? query.vendor : null;
  const onlyWeb = query.onlyWeb ? query.onlyWeb : false;

  useEffect(() => {
    let apiEndpoint = "/venues/" + props.match.params.venueId + "/hotlink";

    if (vendorId) {
      apiEndpoint += `?vendor=${vendorId}`;
    }

    CommonApiAxios("POST", apiEndpoint, {})
      ?.then((Response: any) => {
        if (vendorId) {
          checkIfVendorExists(Response?.data.data)
            ? setDetails(Response?.data.data.restaurant)
            : setError("Vendor is not a part of this venue");
        } else {
          if (Response.data.data.venue) setDetails(Response?.data.data.venue);
          else setError("Venue is not available");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [props]);

  function checkIfVendorExists(res: any) {
    if (res.venue.restaurantDetails.indexOf(res.restaurant._id) > -1) {
      return true;
    }
    return false;
  }

  function openInApp() {
    setTimeout(redirectAppStore, 25);
    if (detectOS.isAndroid() || detectOS.isIos()) {
      if (vendorId) {
        window.open(
          "noq://venues/" + props.match.params.venueId + "/vendors/" + vendorId
        );
      } else {
        if (details.restaurantDetails && details.restaurantDetails.length > 1) {
          window.open("noq://venues/" + props.match.params.venueId);
        } else {
          window.open(
            "noq://venues/" +
              props.match.params.venueId +
              "/vendors/" +
              details.restaurantDetails[0]
          );
        }
      }
    }
  }

  return (
    <>
      {error ? (
        notify(error) && <NotFoundComponent />
      ) : (
        <div className="landing-container">
          {isLoading ? (
            <ReactLoading
              type={"bars"}
              color={"#7422c5"}
              height={100}
              width={100}
            />
          ) : (
            <Landing
              details={details}
              continueBrowser={() => {
                history.push(
                  continueBrowserUrl(
                    vendorId,
                    null,
                    props.match.params.venueId,
                    null,
                    details.restaurantDetails
                  )
                );
              }}
              openInApp={openInApp}
              error={error}
              onlyWeb={onlyWeb}
            />
          )}
        </div>
      )}
    </>
  );
}
