import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import Popup from "reactjs-popup";

import {
  increaseQuantity,
  decreaseQuantity,
  deleteItemFromCart,
} from "../../store/actions/productActions";

import "./MyBasket.scss";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";

const Orders = (props: any) => {
  const { name, price, currency } = props;
  const [productCount, setProductCount] = useState(props.productQuantity);

  const toppingList = props.toppings;

  function calculateSubTotal() {
    if (props.item.quantity) {
      return props.item.subTotal / props.item.quantity;
    }
    return 0;
  }

  useEffect(() => {
    if (productCount !== props.productQuantity) {
      setProductCount(props.productQuantity);
    }
  }, [props]);

  const increaseItemQuantity = (item: any, index: any) => {
    setProductCount(productCount + 1);
    props.raiseQuantity(item, index);
  };

  const decreaseItemQuantity = (item: any, index: any) => {
    if (productCount > 1) {
      setProductCount(productCount - 1);
      props.lessQuantity(item, index);
    }
  };

  const removeItemFromCart = () => {
    props.deleteItem(props.indexOfItem);
  };

  return (
    <div className="order">
      <div className="order-card">
        <div className="order-description">{name}</div>
        <div className="order-detail">
          <div>
            <Popup
              trigger={
                <div
                  style={{
                    fontSize: "13px",
                    margin: "5px 0",
                    color: "rgb(229 162 245)",
                  }}
                >
                  Details
                </div>
              }
              modal
            >
              {(close: any) => (
                <div className="details-popup">
                  <div className="details-popup-nav">
                    <span
                      style={{
                        alignSelf: "end",
                        width: "70vw",
                        maxWidth: "250px",
                      }}
                    >
                      {name}
                    </span>
                    <span
                      style={{
                        fontSize: "35px",
                        color: "#7422c5",
                        cursor: "pointer",
                      }}
                      onClick={close}
                    >
                      x
                    </span>
                  </div>
                  <div className="details-breakdown-container">
                    {props.itemVariant ? (
                      <>
                        <div className="details-breakdown">
                          <span style={{ fontSize: "15px" }}>Variant</span>
                        </div>
                        <div
                          className="details-breakdown"
                          style={{
                            fontSize: "12px",
                            fontFamily: "EuropaLight",
                          }}
                        >
                          <span>{props.itemVariant}</span>
                          <span style={{ color: "#798d06" }}>
                            <FormattedNumber
                              value={props.variantPrice}
                              style="currency"
                              minimumFractionDigits={2}
                              maximumFractionDigits={2}
                              currency={currency}
                            />
                          </span>
                        </div>
                      </>
                    ) : (
                      <div
                        className="details-breakdown"
                        style={{ fontSize: "15px" }}
                      >
                        <span>{"Base Price"}</span>
                        <span style={{ color: "#798d06" }}>
                          <FormattedNumber
                            value={props.item.price}
                            style="currency"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={currency}
                          />
                        </span>
                      </div>
                    )}
                    {props.toppings.length > 0 && (
                      <>
                        <div
                          className="details-breakdown"
                          style={{ fontSize: "15px" }}
                        >
                          <span>Toppings</span>
                        </div>

                        {toppingList.map((topping: any, index: any) => {
                          return (
                            <div
                              className="details-breakdown"
                              style={{
                                fontSize: "12px",
                                fontFamily: "EuropaLight",
                              }}
                              key={index}
                            >
                              <span>{topping.name}</span>
                              <span style={{ color: "#798d06" }}>
                                <FormattedNumber
                                  value={topping.price}
                                  style="currency"
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                  currency={currency}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </>
                    )}
                    <div
                      className="details-breakdown"
                      style={{ fontSize: "15px" }}
                    >
                      <span>Quantity</span>
                      <span style={{ color: "#798d06" }}>
                        {props.item.quantity}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="details-popup-total">
                    <span>Total Amount</span>
                    <span style={{ color: "#798d06" }}>
                      <FormattedNumber
                        value={price}
                        style="currency"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        currency={currency}
                      />
                    </span>
                  </div>
                </div>
              )}
            </Popup>
            <span className="order-price">
              <FormattedNumber
                value={calculateSubTotal()}
                style="currency"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                currency={currency}
              />
            </span>
          </div>
          <div style={{ display: "flex" }}>
            {productCount === 1 ? (
              <div className="order-minus">
                <Delete height="20" onClick={removeItemFromCart} />
              </div>
            ) : (
              <div
                className="order-minus"
                onClick={() =>
                  decreaseItemQuantity(props.item, props.indexOfItem)
                }
              >
                -
              </div>
            )}
            <div className="order-item">{productCount}</div>
            <div
              className="order-plus"
              onClick={() =>
                increaseItemQuantity(props.item, props.indexOfItem)
              }
            >
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    raiseQuantity: (item: any, index: any) => {
      dispatch(increaseQuantity(item, index));
    },
    lessQuantity: (item: any, index: any) => {
      dispatch(decreaseQuantity(item, index));
    },
    deleteItem: (index: any) => {
      dispatch(deleteItemFromCart(index));
    },
  };
};

export default connect(null, mapDispatchToProps)(Orders);
