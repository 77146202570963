import { useEffect, useState, Fragment } from "react";
import { useHistory, Prompt } from "react-router-dom";
import { format, parseISO } from "date-fns";
import ReactLoading from "react-loading";
import { FormattedNumber, IntlProvider } from "react-intl";

import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";
import { notify } from "../../utils/utils";

import "./YourOrder.scss";
import { ReactComponent as BackArrow } from "../../assets/imgs/left-arrow.svg";
import Constants from "../../constants";

function YourOrder(props: any) {
  const history = useHistory();
  const orderId = props.match.params.orderId;
  const search = new URLSearchParams(props.location.search);
  const isFromMail = search.get("mailLink") ? true : false;
  const [orderDetails, setOrderDetails] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState("");

  useEffect(() => {
    window.history.pushState(null, "", location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
    return function cleanup() {
      window.onpopstate = function (event) {};
    };
  }, []);
  const redirectUrl = (orderDetails: any) => {
    if (orderDetails && orderDetails.eventRef) {
      if (
        orderDetails.eventRef.restaurantZones &&
        orderDetails.eventRef.restaurantZones.length > 1
      ) {
        // redirect to event zones page
        return "/events/" + orderDetails.eventRef._id + "/zones";
      } else {
        // redirect to event vendor list page
        return "/events/" + orderDetails.eventRef._id + "/vendors";
      }
    } else if (orderDetails && orderDetails.venueRef) {
      if (orderDetails.venueRef.restaurantDetails.length === 1) {
        // redirect to menu page
        return (
          "/menu/" +
          orderDetails.restaurantRef._id +
          "?venueId=" +
          orderDetails.venueRef._id
        );
      } else {
        // redirect to venue vendor list page
        return "/venues/" + orderDetails.venueRef._id + "/vendors";
      }
    } else {
      return "/";
    }
  };

  useEffect(() =>  {
    CommonApiAxios("GET", "/orders/" + orderId, {})
      ?.then((Response) => {
        setOrderDetails(Response?.data.data.order);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
      let tempOrderStatus = "";

      if (orderDetails) {
          if (orderDetails.orderType === "Collection") {
              if (orderDetails.status === "PENDING") {
                  tempOrderStatus = "Order Placed";
              } else if (orderDetails.status === "ACCEPTED") {
                  tempOrderStatus = "Order Accepted";
              } else if (orderDetails.status === "READY FOR PICKUP") {
                  tempOrderStatus = "Ready to Collect";
              } else if (orderDetails.status === "COMPLETED") {
                  tempOrderStatus = "Collected";
              } else {
                  tempOrderStatus = orderDetails.status;
              }
          } else if (orderDetails.orderType === "On-Site Delivery") {
              if (orderDetails.status === "PENDING") {
                  tempOrderStatus = "Order Placed";
              } else if (orderDetails.status === "ACCEPTED") {
                  tempOrderStatus = "Order Accepted";
              } else if (orderDetails.status === "READY FOR PICKUP") {
                  tempOrderStatus = "On the way";
              } else if (orderDetails.status === "COMPLETED") {
                  tempOrderStatus = "Delivered";
              } else {
                  tempOrderStatus = orderDetails.status;
              }
          }
      }

      tempOrderStatus = tempOrderStatus.toUpperCase();
      setOrderStatus(tempOrderStatus);
  }, [orderDetails]);

  const renderQrCode = () => {
    if (isLoading) {
      return null;
    }

    if (
      orderDetails && orderDetails.restaurantRef.enableExpressOrder && 
      (orderDetails.status === "ACCEPTED" || orderDetails.status === "READY FOR PICKUP")
    ) {

      const qrCodeUrl = Constants.imageURL + "/" + orderDetails.qrCodeUrl;
      
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                margin: 0,
                fontSize: "15px",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {orderDetails.vendorZoneName ? orderDetails.vendorZoneName + " |" : ""} {orderDetails.restaurantRef.displayName}
            </h3>
            <br></br>
          </div>
          <div>
            <h1
              style={{
                fontSize: "30px",
                color: "#7422c5",
                textAlign: "center",
                margin: 0,
              }}
            >
              {orderStatus}
            </h1>
            <br></br>
            <h4
              style={{
                textAlign: "center",
                margin: 0,
              }}
            >
              Show QrCode at the Express Point
            </h4>
            <div
              style={{
                textAlign: "center",
                fontSize: "13px",
                color: "black",
              }}
            >
              We have sent you an email with the link to re-access this page
            </div>
          </div>
          <img src={qrCodeUrl} alt="qr code" height="100%" width="100%"/>
          <h4
            style={{
              textAlign: "center",
              fontSize: "17px",
              color: "black",
              marginBlockEnd: "10px",
              marginBlockStart: "0",
            }}
          >
            {orderDetails.orderNum}
          </h4>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                margin: 0,
                fontSize: "32px",
                textAlign: "center",
              }}
            >
              {orderDetails.orderNum}
            </h2>
            <div
              style={{
                textAlign: "center",
                marginTop: "0px",
                marginBottom: "0px",
                paddingLeft: "40px",
                paddingRight: "40px",
                fontSize: "15px",
                color: "black",
              }}
            >
              We have sent you an email with the link to re-access this page
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h1
              style={{
                fontSize: "40px",
                color: "#7422c5",
                textAlign: "center",
                marginBlockEnd: "0px",
              }}
            >
              {orderStatus}
            </h1>
            <h4
              style={{
                textAlign: "center",
                marginBlockStart: "0px",
                marginBlockEnd: "0px",
              }}
            >
              Please refresh the page to check the updated status
            </h4>
          </div>
        </>
      );
    }
  };
  
  return (
    <IntlProvider locale="en">
      <>
        {error ? notify(error) : null}
        <div>
           <div className="your-order">
            <div className="your-order-nav">
              {/* {!isFromMail ? ( */}
                <div
                  className="your-order-nav-top"
                  onClick={() => {
                    history.replace(redirectUrl(orderDetails));
                  }}
                >
                  <BackArrow
                    className="your-order-back"
                    height="30"
                    width="30"
                    id="back"
                  />
                  <div
                    style={{
                      color: "white",
                      alignSelf: "center",
                      marginLeft: "15px",
                      fontFamily: "EuropaRegular",
                    }}
                  >
                    Go back
                  </div>
                </div>
              {/* ) : null} */}
              <h2 className="your-order-nav-heading">Your Order</h2>
              <div className="your-order-nav-sub-heading">
                Check the status below
              </div>
            </div>
            <div className="your-order-container">
              {isLoading ? (
                <div className="loading">
                  <ReactLoading
                    type={"bars"}
                    color={"#7422c5"}
                    height={100}
                    width={100}
                  />
                </div>
              ) : (
                <>
                  {renderQrCode()}
                  <hr />
                    <div style={{ marginBottom: "50px" }}>
                    <h3>Order Placed</h3>
                    <div>
                      {format(
                        parseISO(orderDetails.createdAt),
                        "E MM/dd/yyyy HH:mm"
                      )}
                    </div>
                    <h3>Order Type</h3>
                    <div>
                      {orderDetails.orderType}
                      {orderDetails.eventZoneIdentifier
                        ? " - " + orderDetails.eventZoneIdentifier
                        : ""}
                    </div>
                    <h3>Summary Order</h3>
                    {orderDetails.items.map((item: any, index: any) => (
                      <Fragment key={item._id}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div>
                            {item.quantity + "x " + item.name}
                            {item.variant
                              ? " (" + item.variant.name.trim() + ")"
                              : ""}
                            <div style={{ marginLeft: "20px" }}>
                              {item.toppings.map((topping: any, index: any) => (
                                <span key={topping._id}>
                                  {" " + topping.name}
                                  {index === item.toppings.length - 1
                                    ? ""
                                    : ","}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div style={{ color: "#f3a8fa" }}>
                            <FormattedNumber
                              value={item.subTotal}
                              style="currency"
                              minimumFractionDigits={2}
                              maximumFractionDigits={2}
                              currency={orderDetails.currency}
                            />
                          </div>
                        </div>
                      </Fragment>
                    ))}
                    {orderDetails.serviceCharge &&
                    orderDetails.serviceCharge != 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div>Service Charge</div>
                        <div style={{ color: "#f3a8fa" }}>
                          <FormattedNumber
                            value={orderDetails.serviceCharge}
                            style="currency"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={orderDetails.currency}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {orderDetails.tip && orderDetails.tip != 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div>
                          Tip ({orderDetails.tip}{" "}
                          {orderDetails.tipType === "percentage"
                            ? "%"
                            : orderDetails.currency}
                          )
                        </div>
                        <div style={{ color: "#f3a8fa" }}>
                          <FormattedNumber
                            value={orderDetails.tipAmount}
                            style="currency"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={orderDetails.currency}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {orderDetails.serviceFee && orderDetails.serviceFee != 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div>Platform Fee</div>
                        <div style={{ color: "#f3a8fa" }}>
                          <FormattedNumber
                            value={orderDetails.serviceFee}
                            style="currency"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={orderDetails.currency}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {orderDetails.deliveryFee &&
                    orderDetails.deliveryFee != 0 ? (
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                        >
                          <div>Delivery Fee</div>
                          <div style={{ color: "#f3a8fa" }}>
                            <FormattedNumber
                                value={orderDetails.deliveryFee}
                                style="currency"
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={orderDetails.currency}
                            />
                          </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        fontSize: "16px",
                        fontFamily: "EuropaBold",
                      }}
                    >
                      <div>TOTAL</div>
                      <div style={{ color: "#ee7cf8" }}>
                        <FormattedNumber
                          value={orderDetails.totalAmount}
                          style="currency"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          currency={orderDetails.currency}
                        />
                      </div>
                    </div>

                      {(orderDetails.vatAt5 && orderDetails.vatAt5 > 0) ?
                        <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: "10px",
                          fontSize: "16px",
                        }}
                      >
                        <div>VAT 5%</div>
                        <div style={{ color: "#ee7cf8" }}>
                          <FormattedNumber
                            value={orderDetails.vatAt5}
                            style="currency"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={orderDetails.currency}
                          />
                        </div>
                      </div>: null}


                      {(orderDetails.vatAt12_5 && orderDetails.vatAt12_5 > 0) ?
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "10px",
                            fontSize: "16px",
                          }}
                        >
                          <div>VAT 12.5%</div>
                          <div style={{ color: "#ee7cf8" }}>
                            <FormattedNumber
                              value={orderDetails.vatAt12_5}
                              style="currency"
                              minimumFractionDigits={2}
                              maximumFractionDigits={2}
                              currency={orderDetails.currency}
                            />
                          </div>
                        </div>: null}

                      
                      {(orderDetails.vatAt20  && orderDetails.vatAt20 > 0) ?
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: "10px",
                          fontSize: "16px",
                        }}
                      >
                        <div>VAT 20%</div>
                        <div style={{ color: "#ee7cf8" }}>
                          <FormattedNumber
                            value={orderDetails.vatAt20}
                            style="currency"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={orderDetails.currency}
                          />
                        </div>
                      </div>:null}

                      
                    <h3 style={{ paddingTop: "30px"}}>Paid by</h3>
                    <div style={{ paddingBottom: "10px" }}>
                      {"CARD "}
                      {orderDetails.cardData
                        ? "(" +
                          orderDetails.cardData.cardType.toUpperCase() +
                          " - " +
                          orderDetails.cardData.lastFourDigit +
                          (orderDetails.paymentMethodType !== "CARD"
                            ? " - " + orderDetails.paymentMethodType
                            : "") +
                          ")"
                        : ""}
                    </div>
                    <h3>Your Details</h3>
                    <div style={{ lineHeight: "30px" }}>
                      {orderDetails.userRef.userName}
                      <br />
                      {orderDetails.userRef.email}
                      <br />
                      {orderDetails.formattedContactNumber && (
                        <>
                          {orderDetails.formattedContactNumber}
                          <br />
                        </>
                      )}
                    </div>
                    <h3>Vendor Details</h3>
                    <div
                      style={{
                        paddingBottom: "50px",
                        lineHeight: "30px",
                      }}
                    >
                      {orderDetails.restaurantRef.displayName}
                      <br />
                      {orderDetails.restaurantRef.phone} <br />
                      {orderDetails.restaurantRef.address} <br />
                      {orderDetails.restaurantRef.postalCode}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <Prompt message="Are you sure you want to leave this page? You will only be able to see your order via the email link sent to you."></Prompt> */}
      </>
    </IntlProvider>
  );
}

export default YourOrder;
