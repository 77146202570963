import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ReactLoading from "react-loading";

import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";
import { notify, getBannerText } from "../../utils/utils";
import { ReactComponent as BackArrow } from "../../assets/imgs/left-arrow.svg";

import "./Vendors.scss";

const Vendors = (props: any) => {
  const history = useHistory();
  let menuUrl = "";
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [vendorList, setVendors] = useState([]);
  const [eventDetails, setEventDetails] = useState({});
  const [venueDetails, setVenueDetails] = useState({});
  const [zoneDetails, setZoneDetails] = useState({});

  useEffect(() => {
    const vendorZone = JSON.parse(localStorage.getItem("vendorZone") || "{}");
    setZoneDetails(vendorZone);
    let apiEndPoint = "";
    if(props.match.params.venueId){
      apiEndPoint = `/venues/${props.match.params.venueId}/hotlink`
      setLoading(true);
      CommonApiAxios("POST", apiEndPoint, {})?.then(resp => {
        setVenueDetails(resp?.data.data)
        setLoading(false);
      }).catch(error => {
        setError(error.message);
        setLoading(false);
      })
    }
  },[])

  useEffect(() => {
    error && setError("");
  }, [error]);

  useEffect(() => {
    let apiEndpoint = "";
    let zoneId = props.location.state;

    if (props.match.params.venueId) {
      apiEndpoint = "/venues/" + props.match.params.venueId + "/vendors";
    } else {
      apiEndpoint = "/events/" + props.match.params.eventId + "/vendors";
    }
    setLoading(true);
    CommonApiAxios("GET", apiEndpoint, {
      page: 0,
      rowsPerPage: 10,
      zoneId: zoneId,
    })
      ?.then((resp) => {
        setVendors(resp?.data.data.restaurants);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
    if (error === "" && props.match.params.eventId) {
      setLoading(true);
      CommonApiAxios(
        "POST",
        "/events/" + props.match.params.eventId + "/hotlink",
        {}
      )
        ?.then((resp) => {
          setEventDetails(resp?.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [props]);

  useEffect(() => {
    if (vendorList && vendorList.length === 1 && props.match.params.venueId) {
      history.replace("/menu/" + vendorList[0]._id + menuUrl, vendorList[0]);
    }
  });

  if (props.match.params.venueId) {
    menuUrl = "?venueId=" + props.match.params.venueId;
  } else {
    menuUrl = "?eventId=" + props.match.params.eventId;
  }

  const vendorStatus = (vendor: any) => {
    const  banner = getBannerText(vendor);
    if (banner && banner.status) {
      return (
          <>
            <h4 style={{ margin: 0, color: banner.textColor, marginBottom: "5px" }}>
              {banner.status}
            </h4>
            <div
                style={{
                  margin: 0,
                  color: banner.textColor,
                  textAlign: "end",
                  fontSize: "15px",
                  fontFamily: "EuropaLight",
                }}
            >
              {banner.message}
            </div>
          </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {error ? notify(error) : null}
      <div className="vendors">
        <div className="vendors-nav">
          <div className="checkout-nav-top">
            <BackArrow
              onClick={() => {
                history.goBack();
              }}
              className="checkout-back"
              height="30"
              width="30"
            />
          </div>
          <h2>
            {zoneDetails && zoneDetails.name ? zoneDetails.name : 
            eventDetails.event && eventDetails.event.name ? eventDetails.event.name : 
            venueDetails.venue && venueDetails.venue.name ? venueDetails.venue.name : ""}
          </h2>
        </div>
        <div className="vendors-container">
          {isLoading ? (
            <div className="loading">
              <ReactLoading
                type={"bars"}
                color={"#7422c5"}
                height={100}
                width={100}
              />
            </div>
          ) : vendorList.length === 0 ? (
            <h4 className="vendors-h-text">No Vendors Available</h4>
          ) : (
            <>
              <h4 className="vendors-h-text">Vendors Available</h4>
              <div className="vendor-list">
                {vendorList.map((vendor: any) => (
                  <div
                    style={{
                      padding: "20px 30px",
                      backgroundColor: "#fafafa",
                      borderRadius: "30px",
                      margin: "20px 0",
                    }}
                    onClick={() => {
                      history.push("/menu/" + vendor._id + menuUrl, vendor);
                    }}
                    key={vendor._id}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="vendor-description">
                        <h4 style={{ margin: 0, lineHeight: "17px" }}>
                          {vendor.displayName}
                        </h4>
                        {vendor.description && (
                          <p
                            style={{
                              display: '-webkit-box',
                              fontFamily: "EuropaLight",
                              fontSize: "15px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "initial",
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 3,
                              margin: "0",
                            }}
                          >
                            {vendor.description}
                          </p>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          width: "105px",
                          lineHeight: "25px",
                        }}
                      >
                        {vendorStatus(vendor)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    basket: state.cart,
  };
};

export default connect(mapStateToProps, null)(Vendors);
