import { toast } from "react-toastify";
import { PINK, PURPLE, GRAY } from "./colors";
import { format, isBefore, isWithinInterval, parseISO, sub } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import detectOS from "@bit/tomlandau.simple-js.platform-detection.detect-os";
import Constants from "../constants";

export function redirectAppStore() {
  if (detectOS.isAndroid()) {
    window.open(
      "https://play.google.com/store/apps/details?id=events.noq.apps",
      "_blank"
    );
  } else if (detectOS.isIos()) {
    window.open("https://apps.apple.com/gb/app/noq/id1515913853", "_blank");
  } else {
    window.open("https://www.noqgroup.com/consumers/", "_blank");
  }
}

export function continueBrowserUrl(
  vendorId,
  eventId,
  venueId,
  restaurantZones,
  restaurantDetails
) {
  if (eventId) {
    const zones = new Set(
      restaurantZones && restaurantZones.map((zone) => zone.vendorZoneId)
    );
    if (vendorId) {
      return "/menu/" + vendorId + "?eventId=" + eventId;
    } else if (zones.size > 1) {
      return "/events/" + eventId + "/zones";
    } else {
        return "/events/" + eventId + "/vendors";
    }
  } else {
    if (vendorId) {
      return "/menu/" + vendorId + "?venueId=" + venueId;
    } else {
      if (restaurantDetails && restaurantDetails.length > 1) {
        return "/venues/" + venueId + "/vendors";
      } else {
        return "/menu/" + restaurantDetails[0] + "?venueId=" + venueId;
      }
    }
  }
}

export function canShowBasket(eventRef, venueRef, vendorRef, basket) {
  if (!basket) {
    return false;
  }

  if (eventRef) {
    if (basket.eventRef !== eventRef || basket.restaurantRef !== vendorRef) {
      return false;
    }
  }

  if (venueRef) {
    if (basket.venueRef !== venueRef || basket.restaurantRef !== vendorRef) {
      return false;
    }
  }

  return true;
}

export function getInBasketCount(basket) {
  var countObject = {};
  for (var i = 0; basket.items && i < basket.items.length; i++) {
    if (countObject[basket.items[i].itemRef]) {
      countObject[basket.items[i].itemRef] += basket.items[i].quantity;
    } else {
      countObject[basket.items[i].itemRef] = basket.items[i].quantity;
    }
  }
  return countObject;
}
export function getBannerText(restaurant) {
    if (!restaurant) {
      return {
        text: "",
        status: "",
        message: "",
        textColor: PURPLE,
      };
    }
  const deliveryTime = restaurant.estimatedPreparationTime
      ? restaurant.estimatedPreparationTime : restaurant.avgDeliveryTime;
    if (restaurant.isRegisterOpen && restaurant.isAvailable) {
      return {
        text: "Open and accepting orders",
        status: "OPEN",
        message: "Estimated Time " + deliveryTime + " mins",
        textColor: PURPLE,
      };
    } else if (restaurant.isRegisterOpen && !restaurant.isAvailable) {
      return {
        text: "We are busy, we’ll be back soon",
        status: "BUSY",
        message: "Vendor will be back soon",
        textColor: PINK,
      };
    } else if (!restaurant.isRegisterOpen) {
      return {
        text: "Closed, we’ll be back soon",
        status: "CLOSED",
        message: "",
        textColor: GRAY,
      };
    } else {
      return {};
    }
}

export function getAvailabilityForPreOrder(dayCount, eventStartDate) {
  const availabilityForPreOrderStartDate = sub(parseISO(eventStartDate), {
    days: dayCount ? dayCount : 0,
  });
  return isBefore(availabilityForPreOrderStartDate, new Date());
}

export function isCanOrderNow(eventDetail, restaurant) {
  return isWithinInterval(utcToZonedTime(new Date(), restaurant.timezone), {
    start: utcToZonedTime(eventDetail.startDate, restaurant.timezone),
    end: utcToZonedTime(eventDetail.endDate, restaurant.timezone),
  });
}

export function isOpenRestaurant(restaurant) {
  const now = utcToZonedTime(new Date(), restaurant.timezone);
  const slotOfDay = getSlotOfDay(format(now, "EEEE"), restaurant.openTimings);
  return (
    slotOfDay &&
    format(now, "HH:mm") < slotOfDay.endTime &&
    format(now, "HH:mm") > slotOfDay.startTime
  );
}

export function getSlotOfDay(day, openTimings) {
  return (
    openTimings &&
    openTimings.filter((timing) => {
      return timing.isAvailable && timing.day === day;
    })[0]
  );
}

export function notify(error) {
  return toast.error(error, { position: "top-center" });
}


export function getVAT(items) {
  const filterItems = items.filter(item => {
    return item.vat && item.vat > 0;
  });

  const groupByVat = filterItems.reduce((r, a) => {
    r[a.vat] = [...r[a.vat] || [], a];
    return r;
   }, {});

  return Object.keys(groupByVat).map((key) => {
    return {
      label: "VAT " + key + "%",
      value: calculateVat(key, groupByVat[key]),
      percentage: parseFloat(key),
    }
  });
}

function calculateVat(vat, items) {
  let subTotal = 0;

  items.map((item) => {
    subTotal += round2digit(round2digit(item.subTotal) / Constants.VAT_LIST[vat]);
  });

  return round2digit(subTotal);
}

function round2digit(numb) {
  return Math.round((numb + Number.EPSILON) * 100) / 100;
}