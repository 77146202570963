import * as actionTypes from "./actionTypes";
import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";

export const addToBasket = (product, basket) => {
  return {
    type: actionTypes.ADD_TO_BASKET,
    basket: basket.push(product),
  };
};

export const removeFromBasket = (product, basket) => {
  return {
    type: actionTypes.REMOVE_FROM_BASKET,
    basket: basket.pop(product),
  };
};

export const addVendorId = (vendorId) => {
  return {
    type: actionTypes.ADD_VENDOR_ID,
    payload: vendorId,
  };
};

export const getProductsStart = () => {
  return {
    type: actionTypes.GET_PRODUCTS_START,
  };
};

export const getProductsSuccess = (products) => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    payload: products,
  };
};

export const getProductsFail = (error) => {
  return {
    type: actionTypes.GET_PRODUCTS_FAIL,
    payload: error,
  };
};

export const emptyCart = () => {
  return {
    type: actionTypes.EMPTY_CART_ACTION,
  };
};

export const getProducts = (vendorId) => {
  return function (dispatch) {
    dispatch(getProductsStart());
    CommonApiAxios("GET", "/items/category-items", {
      restaurantId: vendorId,
      type: "active",
    })
      .then((response) => {
        const productList = response.data.data.categoryGroup;
        dispatch(getProductsSuccess(productList));
        dispatch(addVendorId(vendorId));
      })
      .catch((error) => {
        dispatch(getProductsFail(error.message));
      });
  };
};

// Refer below functions for logic
export const getUserCart = () => {
  return {
    type: actionTypes.GET_USER_CART,
  };
};

export const addItemsTocart = (itemDetails, eventOrVenueRef, type) => {
  let eventRef = type === "event" ? eventOrVenueRef : null;
  let venueRef = type === "venue" ? eventOrVenueRef : null;

  let cartSKU = `${itemDetails.item._id}`;

  if (itemDetails.variant && Object.keys(itemDetails.variant).length > 0) {
    cartSKU += `-${itemDetails.variant._id}`;
  }

  let tempSKU = "";

  if (itemDetails.toppings && Object.keys(itemDetails.toppings).length > 0) {
    tempSKU = itemDetails.toppings
      .map((ele) => {
        return ele._id;
      })
      .sort()
      .join("");
    cartSKU += `-${tempSKU}`;
  }

  itemDetails.cartSKU = cartSKU;

  return async (dispatch, getState) => {
    let { cart } = getState();

    const { item } = itemDetails;

    const matchRef = () => {
      let refMismatch = false;
      if (
        type === "event" &&
        (eventOrVenueRef !== cart.eventRef ||
          itemDetails.resId !== cart.restaurantRef)
      ) {
        dispatch(emptyCart());
        refMismatch = true;
      } else if (
        type === "venue" &&
        (eventOrVenueRef !== cart.venueRef ||
          itemDetails.resId !== cart.restaurantRef)
      ) {
        dispatch(emptyCart());
        refMismatch = true;
      }
      return refMismatch;
    };

    const checkMenuItems = async (item) => {
      const { items } = cart;
      let isItemFound = false;
      let itemIndex = -1;
      itemDetails &&
        items.forEach((itemData, index) => {
          if (itemData.cartSKU === itemDetails.cartSKU) {
            isItemFound = true;
            itemIndex = index;
          }
        });
      return { isItemFound, itemIndex };
    };

    const isRefMatched = await matchRef();

    if (
      !isRefMatched &&
      cart &&
      Object.keys(cart).length > 0 &&
      cart.items &&
      cart.items.length > 0
    ) {
      let { items } = cart;
      const checkedItem = await checkMenuItems(item);


      if (checkedItem.isItemFound) {
        items = items.map((itemData, index) => {
          if (index === checkedItem.itemIndex) {
            return {
              ...itemData,
              quantity: itemData.quantity + itemDetails.itemQuantity,
              subTotal: countSubTotal(
                item,
                itemData.variant,
                itemData.toppings,
                itemData.quantity + itemDetails.itemQuantity
              ),
            };
          } else {
            return itemData;
          }
        });
        cart.items = items;
      } else {
        items.push({
          itemRef: item._id,
          name: item.name,
          imageUrl: item.imageUrl ? item.imageUrl : "",
          restaurantDetails: itemDetails.resId,
          categoryDetails: item.categoryRef,
          price: item.price,
          variant: itemDetails.variant,
          toppings: itemDetails.toppings,
          quantity: itemDetails.itemQuantity,
          specialInstructions: itemDetails.specialInstructions,
          cartSKU: itemDetails.cartSKU,
          areaRef: item.areaRef ? item.areaRef : null,
          categoryName: item.categoryName ? item.categoryName : null,
          subTotal: countSubTotal(
            item,
            itemDetails.variant,
            itemDetails.toppings,
            itemDetails.itemQuantity
          ),
          vat: itemDetails.vat,
        });
        cart.items = items;
      }

      let totalAmount = 0;

      if (items) {
        items &&
          items.map((item) => {
            return (totalAmount += item.subTotal);
          });
      }

      cart.subTotal = totalAmount;

      if (cart.tip && cart.tipType) {
        totalAmount = tipCalculator(totalAmount, cart.tip, cart.tipType);
      }

      cart.totalAmount = totalAmount;
    } else {
      let newCart = {};
      newCart.items = [
        {
          itemRef: item._id,
          name: item.name,
          imageUrl: item.imageUrl ? item.imageUrl : "",
          restaurantDetails: itemDetails.resId,
          categoryDetails: item.categoryRef,
          price: item.price,
          variant: itemDetails.variant,
          toppings: itemDetails.toppings,
          quantity: itemDetails.itemQuantity,
          specialInstructions: itemDetails.specialInstructions,
          cartSKU: itemDetails.cartSKU,
          areaRef: item.areaRef ? item.areaRef : null,
          categoryName: item.categoryName ? item.categoryName : null,
          subTotal: countSubTotal(
            item,
            itemDetails.variant,
            itemDetails.toppings,
            itemDetails.itemQuantity
          ),
          vat: itemDetails.vat,
        },
      ];
      newCart = {
        ...newCart,
        subTotal: newCart.items[0].subTotal,
        totalAmount: newCart.items[0].subTotal,
        restaurantRef: itemDetails.resId,
        currency: itemDetails.currency,
        eventRef: eventRef,
        venueRef: venueRef,
        serviceChargeInPercentage: itemDetails.serviceChargeInPercentage,
        serviceChargeInFix: itemDetails.serviceChargeInFix,
        commissionPercentage: itemDetails.commissionPercentage,
        serviceFeeFix: itemDetails.serviceFeeFix,
        absoluteMinimumOrder: itemDetails.absoluteMinimumOrder,
        onSiteDeliveryFee: itemDetails.onSiteDeliveryFee,
        zones: itemDetails.zones,
        zoneIdentifier: itemDetails.zoneIdentifier,
        customerLocationType: itemDetails.customerLocationType,
        serviceType: itemDetails.serviceType,
        serviceFee: 0,
        serviceCharge: 0,
        discountAmount: 0,
        // totalAmountAfterDiscount: newCart.items[0].subTotal,
      };
      cart = newCart;
    }
    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export const increaseQuantity = (itemInfo, itemIndex) => {
  return async (dispatch, getState) => {
    let { cart } = getState();

    const items = cart.items.map((itemData, index) => {
      if (itemData.itemRef === itemInfo.itemRef && index === itemIndex) {
        itemData.quantity += 1;
        itemData.subTotal = countSubTotal(
          itemData,
          itemData.variant,
          itemData.toppings,
          itemData.quantity
        );
      }

      return itemData;
    });

    let totalAmount = 0;

    if (items) {
      items &&
        items.map((item) => {
          return (totalAmount += item.subTotal);
        });
    }

    cart.subTotal = totalAmount;

    if (cart.tip && cart.tipType) {
      totalAmount = tipCalculator(totalAmount, cart.tip, cart.tipType);
    }

    cart.items = items;
    cart.totalAmount = totalAmount;

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export const decreaseQuantity = (item, itemIndex) => {
  return async (dispatch, getState) => {
    let { cart } = getState();

    const items = cart.items
      .map((itemData, index) => {
        if (itemData.itemRef === item.itemRef && index === itemIndex) {
          itemData.quantity = itemData.quantity - 1;
          itemData.subTotal = countSubTotal(
            itemData,
            itemData.variant,
            itemData.toppings,
            itemData.quantity
          );
        }

        return itemData;
      })
      .filter((item) => {
        return item.quantity > 0;
      });

    let totalAmount = 0;

    if (items) {
      items &&
        items.map((item) => {
          return (totalAmount += item.subTotal);
        });
    }

    cart.subTotal = totalAmount;

    if (cart.tip && cart.tipType) {
      totalAmount = tipCalculator(totalAmount, cart.tip, cart.tipType);
    }

    cart.items = items;
    cart.totalAmount = totalAmount;

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export const deleteItemFromCart = (itemIndex) => {
  return async (dispatch, getState) => {
    const { cart } = getState();

    const updatedItems = cart.items.filter((itemdata, index) => {
      return index !== itemIndex;
    });

    let totalAmount = 0;

    if (updatedItems) {
      updatedItems.map((item) => {
        return (totalAmount += item.subTotal);
      });
    }

    cart.subTotal = totalAmount;

    if (cart.tip && cart.tipType) {
      totalAmount = tipCalculator(totalAmount, cart.tip, cart.tipType);
    }

    cart.items = updatedItems;
    cart.totalAmount = totalAmount;

    if (cart.items && cart.items.length < 1) {
      dispatch(emptyCart());
    } else {
      dispatch({
        type: actionTypes.ADD_ITEMS_CART_ACTION,
        payload: { ...cart },
      });
    }
  };
};

export const injectServiceChargeToCart = (serviceCharge) => {
  return async (dispatch, getState) => {
    let { cart } = getState();
    let totalAmount = 0;

    if (cart.items) {
      cart.items.map((item) => {
        return (totalAmount += item.subTotal);
      });
    }

    cart.serviceCharge = serviceCharge;
    cart.totalAmount = totalAmount + serviceCharge;

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export const injectDeliveryFeeToCart = (deliveryFee) => {
  return async (dispatch, getState) => {
    let { cart } = getState();
    let updatedCart = { ...cart };

    let totalAmount = updatedCart.totalAmount;

    updatedCart.deliveryFee = deliveryFee;
    updatedCart.totalAmount = totalAmount + deliveryFee;

    cart = updatedCart;

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export const injectTheTipIntoCart = (tip, type) => {
  return async (dispatch, getState) => {
    let { cart } = getState();
    let updatedCart = { ...cart };

    let totalAmount = updatedCart.totalAmount;
    let tipToAdd = 0;

    // if (updatedCart.items) {
    //   updatedCart.items.map((item) => {
    //     return (totalAmount += item.subTotal);
    //   });
    // }

    updatedCart.tip = Number(tip);
    updatedCart.tipType = type;

    if (updatedCart.tip) {
      if (type === "percentage") {
        tipToAdd = (totalAmount * tip) / 100;
      } else {
        tipToAdd = tip;
      }
      updatedCart.totalAmount += tipToAdd;
      updatedCart.tipAmount = tipToAdd;
    } else {
      updatedCart.tipAmount = 0;
    }

    cart = updatedCart;

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export const injectServiceFeeToCart = (serviceFee) => {
  return async (dispatch, getState) => {
    let { cart } = getState();
    let updatedCart = { ...cart };

    let totalAmount = updatedCart.totalAmount;

    updatedCart.serviceFee = serviceFee;
    updatedCart.totalAmount = totalAmount + serviceFee;

    cart = updatedCart;

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export const injectOrdeTypeToCart = (orderType) => {
  return async (dispatch, getState) => {
    const { cart } = getState();

    cart.orderType = orderType;

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
  };
};

export function countSubTotal(item, variant, toppings, quantity) {
  let subTotal = 0;

  if (variant && Object.keys(variant).length > 0) {
    subTotal += variant.price;
  } else {
    subTotal += item.price;
  }

  if (toppings && toppings.length > 0) {
    toppings.forEach((topping) => {
      subTotal += topping.price;
    });
  }

  return subTotal * quantity;
}

export function tipCalculator(totalAmount, tip, tipType) {
  if (tip && tipType === "percentage") {
    totalAmount = totalAmount + (totalAmount * tip) / 100;
  } else if (tip && tipType === "fixed") {
    totalAmount += tip;
  } else {
    totalAmount = totalAmount;
  }
  return totalAmount;
}

export const setReload = (reload) => {
  return {
    type: actionTypes.MENU_RELOAD,
    payload: reload,
  };
};
