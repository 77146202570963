import { useState } from "react";

import Constants from "../../constants";

import "./Landing.scss";
import logo from "../../assets/imgs/logo.png";
import { useEffect } from "react";

export default function Landing(props: any) {
  const [done, setDone] = useState(false);

  useEffect(() => {
    const isOldZonesAvailable = localStorage.getItem("vendorZone")
    if(Boolean(isOldZonesAvailable)){
      localStorage.removeItem("vendorZone")
    }
  },[])

  const displayLogo = () => {
    if (props.details.websiteUrl) {
      if (props.details.logoUrl) {
        return (
          <a
            href={props.details.websiteUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={Constants.imageURL + "/" + props.details.logoUrl}
              alt={props.details.name}
              onLoad={() => setDone(true)}
            />
            <p className="display-name" style={{ marginTop: "5px" }}>
              {props.details.name}
            </p>
          </a>
        );
      } else {
        return (
          <a
            href={props.details.websiteUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="display-name" style={{ marginTop: "5px" }}>
              {props.details.name}
            </p>
          </a>
        );
      }
    } else {
      if (props.details.logoUrl) {
        return (
          <>
            <img
              src={Constants.imageURL + "/" + props.details.logoUrl}
              alt={props.details.name}
              onLoad={() => setDone(true)}
            />
            <p className="display-name" style={{ marginTop: "5px" }}>
              {props.details.name}
            </p>
          </>
        );
      } else {
        return (
          <p className="display-name" style={{ marginTop: "5px" }}>
            {props.details.name}
          </p>
        );
      }
    }
  };

  return (
    <div
      className="landing"
      style={!props.details.logoUrl || done ? {} : { display: "None" }}
    >
      <div className="welcome">
        <h3 className="welcome-heading">Welcome to</h3>
        {displayLogo()}
      </div>
      <div className="access">
        <h4 className="access-heading">Access Menu</h4>
        {!props.onlyWeb &&
          <p className="access-p-text">
            Get the app to access discount and loyalty schemes
          </p>
        }
        <div className="access-buttons">
          {!props.onlyWeb &&
          <div className="access-button" onClick={props.openInApp}>
            <h3 style={{ margin: 0, fontFamily: "EuropaRegular" }}>
              Open in NOQ app
            </h3>
          </div>
          }
          <div className="access-button" onClick={props.continueBrowser}>
            <h3 style={{ margin: 0, fontFamily: "EuropaRegular" }}>
              Continue on this browser
            </h3>
          </div>
        </div>
      </div>
      <footer>
        <p>Powered by</p>
        <a
          href="https://www.noqgroup.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={logo} alt="NOQ Logo" width="70" />
        </a>
      </footer>
    </div>
  );
}
