import { useState, useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import detectOS from "@bit/tomlandau.simple-js.platform-detection.detect-os";

import Landing from "./Landing";
import NotFoundComponent from "../ErrorComponent/NotFoundComponent";
import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";
import {
  notify,
  redirectAppStore,
  continueBrowserUrl,
} from "../../utils/utils";

import "./Landing.scss";

export default function Event(props: any) {
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(true);

  const query = queryString.parse(props.location.search);
  const vendorId = query.vendor ? query.vendor : null;
  const onlyWeb = query.onlyWeb ? query.onlyWeb : false;

  useEffect(() => {
    let apiEndpoint = "/events/" + props.match.params.eventId + "/hotlink";
    if (vendorId) {
      apiEndpoint += `?vendor=${vendorId}`;
    }

    CommonApiAxios("POST", apiEndpoint, {})
      ?.then((Response: any) => {
        if (vendorId) {
          checkIfVendorExists(Response?.data.data)
            ? setDetails(Response?.data.data.restaurant)
            : setError("Vendor is not a part of this event");
        } else {
          if (Response.data.data.event) setDetails(Response?.data.data.event);
          else setError("Event is not available");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [props]);

  function checkIfVendorExists(res: any) {
    if (res.event.restaurantDetails.indexOf(res.restaurant._id) > -1) {
      return true;
    }
    return false;
  }

  function openInApp() {
    setTimeout(redirectAppStore, 25);
    if (detectOS.isAndroid() || detectOS.isIos()) {
      if (vendorId) {
        window.open(
          "noq://events/" + props.match.params.eventId + "/" + vendorId,
          "_blank"
        );
      } else if (
        details.restaurantZones &&
        details.restaurantZones.length > 1
      ) {
        window.open(
          "noq://events/" + props.match.params.eventId + "/zones",
          "_blank"
        );
      } else {
        window.open("noq://events/" + props.match.params.eventId, "_blank");
      }
    }
  }

  return (
    <>
      {error ? (
        notify(error) && <NotFoundComponent />
      ) : (
        <div className="landing-container">
          {isLoading ? (
            <ReactLoading
              type={"bars"}
              color={"#7422c5"}
              height={100}
              width={100}
            />
          ) : (
            <Landing
              details={details}
              continueBrowser={() => {
                history.push(
                  continueBrowserUrl(
                    vendorId,
                    props.match.params.eventId,
                    null,
                    details.restaurantZones,
                    details.restaurantDetails
                  )
                );
              }}
              openInApp={openInApp}
              error={error}
              onlyWeb={onlyWeb}
            />
          )}
        </div>
      )}
    </>
  );
}
