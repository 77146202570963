import { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import { FormattedNumber } from "react-intl";

import { canShowBasket } from "../../utils/utils";
import { addItemsTocart, setReload } from "../../store/actions/productActions";

import "./ProductDetail.scss";
import { ReactComponent as BackArrow } from "../../assets/imgs/left-arrow-black.svg";
import { ReactComponent as CartBag } from "../../assets/imgs/cartbag-black.svg";
import CaretDown from "../../assets/imgs/caret-down.png";
import CaretUp from "../../assets/imgs/caret-up.png";

function ProductDetail(props: any) {
  const history = useHistory();
  const { addToBasket, basket, setMenuReload } = props;
  const { product, vendor } = props.location.state;
  const [variantCaretClicked, setVariantCaretClicked] = useState(true);
  const [toppingCaretClicked, setToppingCaretClicked] = useState(false);
  const [showBasket, setShowBasket] = useState(false);

  const [itemDetail, setItemDetail] = useState({
    item: product,
    resId: vendor.restaurant._id,
    currency: vendor.restaurant.currency,
    serviceChargeInPercentage: vendor.restaurant.serviceChargeInPercentage,
    serviceChargeInFix: vendor.restaurant.serviceChargeInFix,
    commissionPercentage: vendor.restaurant.commissionPercentage,
    serviceFeeFix: vendor.restaurant.serviceFeeFix,
    absoluteMinimumOrder: vendor.restaurant.absoluteMinimumOrder,
    onSiteDeliveryFee: vendor.restaurant.onSiteDeliveryFee,
    zones: vendor.event ? vendor.event.zones : vendor.venue.zones,
    zoneIdentifier: vendor.event
      ? vendor.event.zoneIdentifier
      : vendor.venue.zoneIdentifier,
    customerLocationType: vendor.event
        ? vendor.event.customerLocationType
        : vendor.venue.customerLocationType,
    serviceType: vendor.restaurant.serviceType,
    variant: product.variants ? product.variants[0] : {},
    toppings: [],
    itemQuantity: 1,
    specialInstructions: "",
    vat: product.vat ? product.vat : 0,
  });

  useEffect(() => {
    setMenuReload(false);
    if (vendor.event) {
      setShowBasket(
        canShowBasket(vendor.event._id, null, vendor.restaurant._id, basket)
      );
    } else {
      setShowBasket(
        canShowBasket(null, vendor.venue._id, vendor.restaurant._id, basket)
      );
    }
  });

  const checkType = () => {
    if (vendor.event) {
      let eventId = vendor.event ? vendor.event._id : null;
      return {
        idRef: eventId,
        type: "event",
      };
    } else {
      let venueId = vendor.venue ? vendor.venue._id : null;
      return {
        idRef: venueId,
        type: "venue",
      };
    }
  };

  const changeVariant = (variant: any) => {
    setItemDetail({
      ...itemDetail,
      variant: variant,
    });
  };

  const addTopping = (topping: any) => {
    setItemDetail({
      ...itemDetail,
      toppings: [...itemDetail.toppings, topping],
    });
  };

  const removeTopping = (topping: any) => {
    setItemDetail({
      ...itemDetail,
      toppings: itemDetail.toppings.filter(
        (elem: any) => elem.name !== topping.name
      ),
    });
  };

  const calcPrice = () => {
    let price = 0;

    if (itemDetail.variant) {
      price = itemDetail.variant.price;
    } else {
      price = itemDetail.item.price;
    }

    if (itemDetail.toppings) {
      for (let topping of itemDetail.toppings) {
        price += topping.price;
      }
    }
    return (price * itemDetail.itemQuantity).toFixed(2);
  };

  const addItemToBasket = () => {
    const refs = checkType();
    addToBasket(itemDetail, refs.idRef, refs.type);
    history.goBack();
  };

  return (
    <div className="product-detail">
      <div className="product-detail-nav-top">
        <BackArrow
          onClick={() => {
            history.goBack();
          }}
          className="product-detail-back"
        />
        <Link
          className="product-detail-menu"
          to={{ pathname: "/my-basket" }}
          style={
            basket && basket.items && basket.items.length && showBasket
              ? {}
              : { display: "none" }
          }
        >
          <h4 className="product-detail-link-text">Go Basket</h4>
          <CartBag height="25" width="25" />
          <span className="product-detail-badge">
            {basket && basket.items ? basket.items.length : "0"}
          </span>
        </Link>
      </div>
      <div className="product-detail-container">
        <h3 className="product-name">{product.name}</h3>
        {product.description && (
          <>
            <h3 style={{ marginBottom: "10px" }}>Description</h3>
            <h5 className="product-description">{product.description}</h5>
          </>
        )}
        {product.tags && product.tags.length > 0 && (
          <>
            <h5 className="categories-label">Important allergy information</h5>
            <h5 className="categories">{product.tags.join(", ")}</h5>
          </>
        )}
        <hr
          style={{
            color: "#706f6f80",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        />

        {product.variants && product.variants.length > 0 && (
          <>
            <div>
              <label
                className="product-details-label"
                onClick={() => {
                  setVariantCaretClicked(!variantCaretClicked);
                }}
              >
                <span>Variants</span>
                {variantCaretClicked ? (
                  <img src={CaretUp} className="caret" />
                ) : (
                  <img src={CaretDown} className="caret" />
                )}
              </label>
            </div>
            <Collapse isOpened={variantCaretClicked}>
              <div className="product-detail-select">
                {product.variants.map((variant: any) => (
                  <div
                    className="flex variant"
                    key={variant._id}
                    onClick={() => {
                      changeVariant(variant);
                    }}
                  >
                    {itemDetail.variant === variant ? (
                      <div
                        className="circle-checked-radio-outline"
                        style={{ margin: "3px 8px 3px 4px" }}
                      >
                        <div className="circle-checked-radio-inner"></div>
                      </div>
                    ) : (
                      <div
                        className="circle-unchecked-radio-outline"
                        style={{ margin: "3px 8px 3px 4px" }}
                      >
                        <div className="circle-unchecked-radio-inner"></div>
                      </div>
                    )}
                    <label
                      className="flex space-between variant-price"
                      htmlFor={variant.name}
                    >
                      <span>{variant.name}</span>
                      <span>
                        <FormattedNumber
                          value={variant.price}
                          style="currency"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          currency={
                            vendor && vendor.restaurant
                              ? vendor.restaurant.currency
                              : "gbp"
                          }
                        />
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </Collapse>
          </>
        )}
        <div style={{ marginTop: "4vh" }}></div>
        {product.toppings && product.toppings.length > 0 && (
          <>
            <div>
              <label
                className="product-details-label"
                onClick={() => {
                  setToppingCaretClicked(!toppingCaretClicked);
                }}
              >
                <span>Extras</span>
                {toppingCaretClicked ? (
                  // <CaretUp height="4vh" width="8vw" />
                  <img src={CaretUp} className="caret" />
                ) : (
                  // <CaretDown height="4vh" width="8vw" />
                  <img src={CaretDown} className="caret" />
                )}
              </label>
            </div>
            <Collapse isOpened={toppingCaretClicked}>
              <div className="product-detail-select">
                {product.toppings.map((topping: any) => (
                  <div className="flex variant" key={topping._id}>
                    <input
                      type="checkbox"
                      id={topping.name}
                      name="extra"
                      value={topping.name}
                      onChange={(e) => {
                        e.target.checked
                          ? addTopping(topping)
                          : removeTopping(topping);
                      }}
                    />
                    <label
                      className="flex space-between variant-price"
                      htmlFor={topping.name}
                    >
                      <span>{topping.name}</span>
                      <span>
                        <FormattedNumber
                          value={topping.price}
                          style="currency"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          currency={
                            vendor && vendor.restaurant
                              ? vendor.restaurant.currency
                              : "gbp"
                          }
                        />
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </Collapse>
          </>
        )}

        <form style={{ marginTop: "30px" }}>
          <input
            className="checkout-form-input"
            type="text"
            name="specialInstructions"
            placeholder="Add extra notes"
            onChange={(e) => {
              setItemDetail({
                ...itemDetail,
                specialInstructions: e.target.value,
              });
            }}
          />
        </form>
        <div style={{ marginTop: "20px" }}>
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>Quantity</div>
          <div
            className="flex space-between align-items-center"
            style={{ margin: "10px 10px 30px 10px" }}
          >
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              <FormattedNumber
                value={calcPrice()}
                style="currency"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                currency={
                  vendor && vendor.restaurant
                    ? vendor.restaurant.currency
                    : "gbp"
                }
              />
            </span>
            <div className="flex">
              <div
                className="order-minus"
                onClick={() => {
                  if (itemDetail.itemQuantity > 1) {
                    setItemDetail({
                      ...itemDetail,
                      itemQuantity: itemDetail.itemQuantity - 1,
                    });
                  }
                }}
              >
                -
              </div>
              <div className="order-item">{itemDetail.itemQuantity}</div>
              <div
                className="order-plus"
                onClick={() => {
                  setItemDetail({
                    ...itemDetail,
                    itemQuantity: itemDetail.itemQuantity + 1,
                  });
                }}
              >
                +
              </div>
            </div>
          </div>
          <button onClick={addItemToBasket} className="add-to-basket-button">
            {"Add To Basket"}
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    basket: state.cart,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addToBasket: (itemDetail: any, eventRefOrVenueRef: any, type: any) => {
      dispatch(addItemsTocart(itemDetail, eventRefOrVenueRef, type));
    },
    setMenuReload: (reload: boolean) => {
      dispatch(setReload(reload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
