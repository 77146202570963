import { Route, Switch } from "react-router-dom";

import Checkout from "./Components/Checkout/Checkout";
import MyBasket from "./Components/MyBasket/MyBasket";
import ProductDetail from "./Components/ProductDetail/ProductDetail";
import ProductList from "./Components/ProductList/ProductList";
import Vendors from "./Components/Vendors/Vendors";
import YourOrder from "./Components/YourOrder/YourOrder";
import Event from "./Components/Landing/Event";
import Venue from "./Components/Landing/Venue";
import Zones from "./Components/Zones/Zones";
import PaymentGateway from "./Components/PaymentGateway/PaymentGateway";
import NotFoundComponent from "./Components/ErrorComponent/NotFoundComponent";

const BaseRouter = () => {
  return (
    <Switch>
      <Route exact path="/menu/:vendorId" component={ProductList} />
      <Route exact path="/product-detail" component={ProductDetail} />
      <Route exact path="/my-basket" component={MyBasket} />
      <Route exact path="/orders/:orderId" component={YourOrder} />
      <Route exact path="/checkout" component={Checkout} />
      <Route exact path="/events/:eventId" component={Event} />
      <Route exact path="/venues/:venueId" component={Venue} />
      <Route exact path="/events/:eventId/zones" component={Zones} />
      <Route exact path="/events/:eventId/vendors" component={Vendors} />
      <Route exact path="/venues/:venueId/vendors" component={Vendors} />
      <Route exact path="/payment-gateway" component={PaymentGateway} />
      <Route component={NotFoundComponent} />
    </Switch>
  );
};

export default BaseRouter;
