import { useMemo, useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";

import "./PaymentGateway.scss";

function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "15px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

const getCardOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontFamily: "Cantarell",
          fontSize: "15px",
          letterSpacing: "0.025em",
          "::placeholder": {
            // color: "#fffff",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

export const CardPayment = ({ popUpClose, placeOrder, setShowLoader }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCardError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const options = getCardOptions();
  const history = useHistory();

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setCardError(event.error ? event.error.message : "");
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setCardError(null);
    if (!stripe || !elements) {
      setCardError("Something went wrong, please reload the page.");
      return;
    }
    setShowLoader(true);

    const card = elements.getElement("card");
    if (card) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card,
      });

      if (error) {
        setCardError(error.message);
        setShowLoader(false);
      } else {
        const orderIdPromise = placeOrder(paymentMethod, stripe);
        orderIdPromise.then((order_id: any) => {
          if (order_id) {
            history.replace(`/orders/${order_id}`);
          }
        });
      }
    }
  };

  return (
    <div className="pay-via-card-modal">
      <form onSubmit={onSubmit}>
        <label>
          <span style={{ fontSize: "18px" }}>Card Details</span>
          <CardElement options={options} onChange={handleChange} />
        </label>
        {cardError && (
          <span
            style={{
              display: "block",
              marginBottom: "0.4rem",
              color: "red",
              marginLeft: "1rem",
              fontSize: "0.9rem",
            }}
          >
            {cardError}
          </span>
        )}
        <div className="flex align-items-center space-between">
          <button
            className="pay-button"
            type="submit"
            disabled={!stripe || disabled}
          >
            Pay
          </button>

          <button className="cancel-button" onClick={popUpClose}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
