import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import Popup from "reactjs-popup";
import axios from "axios";

import { CardPayment } from "./CardPayment";
import { WalletPayment } from "./WalletPayment";
import { emptyCart } from "../../store/actions/productActions";
import { notify } from "../../utils/utils";
import Constants from "../../constants";

import "./PaymentGateway.scss";
import { ReactComponent as BackArrow } from "../../assets/imgs/left-arrow.svg";

const fetchPaymentIntent = async (paymentMethod: any, basket: any) => {
  const body = {
    paymentMethod: paymentMethod,
    cart: basket,
  };

  const paymentIntent = await axios
    .post(Constants.serverURL + "/payments/create/web", body)
    .then((resp) => {
      return {
        paymentIntentResponse: resp.data.data.payment_intent,
        cart: resp.data.data.cart,
        paymentIntentError: "",
      };
    })
    .catch((err) => {
      return { paymentIntentResponse: "", cart: null, paymentIntentError: err };
    });

  return paymentIntent;
};

const confirmPaymentIntent = async (
  stripe: any,
  paymentIntent: any,
  handleActions: boolean
) => {
  if (!stripe || !paymentIntent) {
    return { error: "Something went wrong, please reload the page." };
  }

  const payload = await stripe
    .confirmCardPayment(paymentIntent.client_secret, {}, { handleActions })
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });

  return payload;
};

const createUserOrder = async (reqData: any) => {
  const vendorZoneName = JSON.parse(localStorage.getItem("vendorZone") || "{}");
  const body = {
    cart: reqData.basket,
    cardData: reqData.cardData,
    paymentIntent: reqData.paymentIntentConfirm.paymentIntent,
    eventZone: reqData.zone,
    eventZoneIdentifier: reqData.values.tableNumber,
    vendorZoneName: vendorZoneName.name,
    name: reqData.values.name,
    email: reqData.values.email,
    contactNumber: reqData.phone ? reqData.phone : null,
    formattedContactNumber: reqData.phone ? `+${reqData.phone}` : null,
    userZoneIdentifier: reqData.userNote ? reqData.userNote : "",
    paymentMethodType: reqData.paymentMethodType,
    newsLetter: reqData.newsLetter,
  };

  const userOrder = await axios
    .post(Constants.serverURL + "/orders/web", body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return userOrder;
};

const PaymentGateway = (props: any) => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    error && setError("");
  });

  const placeOrder = async (paymentMethod: any, stripe: any, complete: any) => {
    const paymentMethodType = paymentMethod.card.wallet
      ? paymentMethod.card.wallet.type.replace(/[^a-z]/gi, "").toUpperCase()
      : "CARD";

    const paymentIntent = await fetchPaymentIntent(paymentMethod, props.basket);

    if (paymentIntent.paymentIntentError) {
      setShowLoader(false);
      setError(paymentIntent.paymentIntentError.response.data.message);
      return;
    }

    const paymentIntentConfirm = await confirmPaymentIntent(
      stripe,
      paymentIntent.paymentIntentResponse,
      false
    );

    if (paymentIntentConfirm && paymentIntentConfirm.error) {
      complete && complete("fail");
      setShowLoader(false);
      setError(paymentIntentConfirm.error.message);
      return;
    }

    complete && complete("success");

    let paymentIntentConfirm3d;

    if (paymentIntentConfirm.paymentIntent.status === "requires_action") {
      setShowLoader(true);
      paymentIntentConfirm3d = await confirmPaymentIntent(
        stripe,
        paymentIntentConfirm.paymentIntent,
        true
      );
    }

    if (paymentIntentConfirm3d && paymentIntentConfirm3d.error) {
      setShowLoader(false);
      setError(paymentIntentConfirm3d.error.message);
      return;
    }

    const cardData = {
      cardType: paymentMethod.card.brand,
      lastFourDigit: paymentMethod.card.last4,
    };

    const reqBody = {
      basket: paymentIntent.cart,
      cardData: cardData,
      paymentMethodType,
      paymentIntentConfirm: paymentIntentConfirm3d
        ? paymentIntentConfirm3d
        : paymentIntentConfirm,
      ...props.location.state.checkoutData,
    };

    const orderDetails = await createUserOrder(reqBody);
    if (orderDetails.status === 200) {
      props.removeCart();
      setShowLoader(false);
      return orderDetails.data.data.order._id;
    } else {
      setShowLoader(false);
      setError(orderDetails.response.data.message);
      return null;
    }
  };

  return (
    <>
      {error ? notify(error) : null}
      <div className="payment-gateway">
        <div className="payment-gateway-nav">
          <div className="payment-gateway-nav-top">
            <BackArrow
              onClick={() => {
                history.goBack();
              }}
              className="payment-gateway-back"
              height="30"
              width="30"
            />
          </div>
          <h2 className="payment-gateway-nav-heading">Payment</h2>
        </div>
        <div className="payment-gateway-container">
          <WalletPayment
            placeOrder={placeOrder}
            basket={props.basket}
            setShowPayLoader={setShowLoader}
          />
          <Popup
            trigger={
              <button className="payment-button">Debit/Credit card</button>
            }
            modal
          >
            {(close: any) => (
              <CardPayment
                popUpClose={close}
                setShowLoader={setShowLoader}
                placeOrder={placeOrder}
              />
            )}
          </Popup>

          <p className="warning">
            While payment is processing, please do not press/close and do not
            put this app into background. It may cause payment failure
          </p>
        </div>
      </div>
      {showLoader && (
        <div className="loader_layer">
          <p className="warning">
            While payment is processing, please do not press/close and do not
            put this app into background. It may cause payment failure
          </p>
          <div className="loader_backdrop">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ReactLoading
                type={"bars"}
                color={"#7422c5"}
                height={80}
                width={80}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeCart: () => dispatch(emptyCart()),
  };
};

const mapStateToProps = (state: any) => {
  return {
    basket: state.cart,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateway);
