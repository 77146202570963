import { useHistory } from "react-router-dom";

export function ErrorFallback({ error, resetErrorBoundary }: any) {
  const history = useHistory();
  return (
    <div
      role="alert"
      style={{
        backgroundColor: "#fff",
        height: "100Vh",
        padding: "5Vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p style={{ margin: "0px" }}>Something went wrong:</p>
      <p>{error.message}</p>
      <div
        style={{
          backgroundColor: "#7422c5",
          color: "white",
          width: "150px",
          padding: "15px",
        }}
        onClick={() => {
          history.goBack();
        }}
      >
        Click here to go back
      </div>
    </div>
  );
}
