import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import * as Yup from "yup";

import "react-phone-input-2/lib/style.css";

import { injectOrdeTypeToCart } from "../../store/actions/productActions";

import "./Checkout.scss";
import { ReactComponent as BackArrow } from "../../assets/imgs/left-arrow.svg";
import CaretDown from "../../assets/imgs/caret-down.png";
import CaretUp from "../../assets/imgs/caret-up.png";
import axios from "axios";
import Constants from "../../constants";
import { notify } from "../../utils/utils";
import ReactLoading from "react-loading";
import { emptyCart } from "../../store/actions/productActions";

function Checkout(props: any) {
  const history = useHistory();
  const [zonesClicked, setZonesClicked] = useState(false);
  const [zone, setZone] = useState("");
  const [userNote, setUserNote] = useState("");
  const [agree, setAgree] = useState(true);
  const [newsLetter, setNewsLetter] = useState(false);
  const [phone, setPhone] = useState("44");
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState("");
  const availCart = { ...props.basket };


  useEffect(() => {
    error && setError("");
  });

  useEffect(() => {
    if (!props.basket || Object.keys(props.basket).length === 0) {
      history.goBack();
    }
  }, []);

  const serviceTypes = availCart
    ? availCart.serviceType
      ? availCart.serviceType.filter(
          (type: any) => type === "Collection" || type === "On-Site Delivery"
        )
      : []
    : [];

  const [orderType, setOrderType] = useState(serviceTypes[0]);

  useEffect(() => {
    orderType && props.injectOrderType(orderType);
  }, [orderType]);

  const createUserOrder = async (reqData: any) => {
    const vendorZoneName = JSON.parse(localStorage.getItem("vendorZone") || "{}");
    const body = {
      cart: reqData.basket,
      paymentIntent: null,
      eventZone: reqData.zone,
      eventZoneIdentifier: reqData.values.tableNumber,
      vendorZoneName: vendorZoneName.name,
      name: reqData.values.name,
      email: reqData.values.email,
      contactNumber: reqData.phone ? reqData.phone : null,
      formattedContactNumber: reqData.phone ? `+${reqData.phone}` : null,
      userZoneIdentifier: reqData.userNote ? reqData.userNote : "",
      paymentMethodType: reqData.paymentMethodType,
      newsLetter: reqData.newsLetter,
    };
  
    const userOrder = await axios
      .post(Constants.serverURL + "/orders/web", body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return userOrder;
  };

  const placeOrder = async (checkoutData: any) => {
    setShowLoader(true);
    const paymentMethodType = "CARD";

    const reqBody = {
      basket: availCart,
      paymentMethodType,
      paymentIntentConfirm: null,
      ...checkoutData,
    };

    const orderDetails = await createUserOrder(reqBody);
    if (orderDetails.status === 200) {
      props.removeCart();
      setShowLoader(false);
      return orderDetails.data.data.order._id;
    } else {
      setShowLoader(false);
      setError(orderDetails.response.data.message);
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      tableNumber: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(2, "Name must be longer than 2 characters")
        .required("Please enter a name"),
      email: Yup.string()
        .trim()
        .email("Invalid email address")
        .required("Please enter an Email Id"),
      tableNumber:
        orderType !== "On-Site Delivery"
          ? Yup.string().trim()
          : Yup.string().trim().required("Please enter a value"),
    }),
    onSubmit: (values: any) => {
      if (availCart.totalAmount > 0) {
        const paymentPath = "/payment-gateway";
        history.push(paymentPath, {
          checkoutData: { zone, userNote, values, phone, newsLetter },
        });
      } else {
        const orderIdPromise =  placeOrder({ zone, userNote, values, phone, newsLetter });
        orderIdPromise.then((order_id: any) => {
          if (order_id) {
            history.replace(`/orders/${order_id}`);
          }
        });
      }
    },
  });

  const onSiteDeliveryError =
    !!(orderType === "On-Site Delivery" &&
        availCart.absoluteMinimumOrder &&
        availCart.subTotal < availCart.absoluteMinimumOrder);

  const disablePayButton = () => {
    if (availCart.zones && availCart.zones.length > 0
        && (!availCart.customerLocationType
            || availCart.customerLocationType !== 'CUSTOMER_INPUT')
    ) {
      if (orderType === "On-Site Delivery" && zone === "") {
        return true;
      }
    }
    if (onSiteDeliveryError) {
      return true;
    }
    if (formik.dirty && formik.isValid && agree) {
      return false;
    }
    return true;
  };

  return (
    <>
    {error ? notify(error) : null}
      <div className="checkout">
        <div className="checkout-nav">
          <div className="checkout-nav-top">
            <BackArrow
              onClick={() => {
                history.goBack();
              }}
              className="checkout-back"
              height="30"
              width="30"
            />
          </div>
          <h2 className="checkout-nav-heading">Check Out</h2>
        </div>
        <div className="checkout-container">
          <div className="checkout-radio-container">
            {serviceTypes.map((serviceType: any) => (
              <div
                key={serviceType}
                onClick={() => {
                  setOrderType(serviceType);
                  props.injectOrderType(serviceType);
                  formik.setErrors({})
                }}
                className={
                  serviceTypes.length > 1
                    ? orderType === serviceType
                      ? "circle-checked-radio"
                      : "circle-unchecked-radio"
                    : ""
                }
              >
                <div
                  className={
                    serviceTypes.length > 1
                      ? orderType === serviceType
                        ? "circle-checked-radio-outline"
                        : "circle-unchecked-radio-outline"
                      : ""
                  }
                >
                  <div
                    className={
                      serviceTypes.length > 1
                        ? orderType === serviceType
                          ? "circle-checked-radio-inner"
                          : "circle-unchecked-radio-inner"
                        : ""
                    }
                  ></div>
                </div>
                <span
                  style={{ margin: "0 0 0 5px", fontFamily: "EuropaRegular" }}
                >
                  <strong>{serviceType}</strong>
                </span>
              </div>
            ))}
          </div>
          {onSiteDeliveryError && (
              <span
                  style={{
                    display: "block",
                    color: "red",
                    fontSize: "0.9rem",
                    margin: "2vh 0 0vh 1rem",
                  }}
              >
                  {`Unfortunately your order has not met the minimum required for onsite delivery, the minimum amount is 
                  ${availCart.absoluteMinimumOrder} ${availCart.currency || "GBP"} `}
                </span>
          )}
          <form className="checkout-form" onSubmit={formik.handleSubmit}>
            {orderType === "On-Site Delivery" && (
              <>
                {availCart && availCart.zones && availCart.zones.length > 0
                && (!availCart.customerLocationType || availCart.customerLocationType !== 'CUSTOMER_INPUT') ? (
                  <>
                    <h3>Select Location</h3>
                    <span
                      className="zone-label flex space-between align-items-center"
                      style={{ color: `${zone ? "#000000" : "#7c7c7c"}` }}
                      onClick={() => setZonesClicked(!zonesClicked)}
                    >
                      {zone ? zone : "Your location"}
                      {zonesClicked ? (
                        <img src={CaretUp} width="40vw" />
                      ) : (
                        <img src={CaretDown} width="40vw" />
                      )}
                    </span>
                    <div
                      className="zones-dropdown"
                      style={
                        !zonesClicked
                          ? { display: "none" }
                          : { position: "sticky" }
                      }
                    >
                      {availCart.zones.map((zone: any) => (
                        <p
                          className="zone-option"
                          onClick={() => {
                            setZone(String(zone));
                            setZonesClicked(!zonesClicked);
                          }}
                          key={String(zone)}
                        >
                          {String(zone)}
                        </p>
                      ))}
                    </div>
                    {zone === "" && formik.dirty ? (
                      <span
                        style={{
                          display: "block",
                          color: "red",
                          fontSize: "0.9rem",
                          margin: "-0.5vh 0 0vh 1rem",
                        }}
                      >
                        Please select a zone
                      </span>
                    ) : null}
                  </>
                ) : (
                  <></>
                )}

                <input
                  className="checkout-form-input"
                  type="text"
                  name="tableNumber"
                  placeholder={
                    availCart.zoneIdentifier
                      ? availCart.zoneIdentifier
                      : "Insert Table Number"
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.tableNumber}
                  onChange={formik.handleChange}
                />

                {formik.touched.tableNumber && formik.errors.tableNumber ? (
                  <span
                    style={{
                      display: "block",
                      color: "red",
                      fontSize: "0.9rem",
                      margin: "-0.5vh 0 0vh 1rem",
                    }}
                  >
                    {formik.errors.tableNumber}
                  </span>
                ) : null}
              </>
            )}
            <input
              className="checkout-form-input"
              type="text"
              name="userNote"
              value={userNote}
              onChange={(e: any) => {
                setUserNote(e.target.value);
              }}
              placeholder="Insert any extra notes"
            />
            <h3 style={{ marginTop: "4vh" }}>Your Information</h3>
            <input
              className="checkout-form-input"
              type="text"
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="Name"
            />
            {formik.touched.name && formik.errors.name ? (
              <span
                style={{
                  display: "block",
                  color: "red",
                  fontSize: "0.9rem",
                  margin: "-0.5vh 0 0vh 1rem",
                }}
              >
                {formik.errors.name}
              </span>
            ) : null}

            <div className="email_input_field">
              <input
                className="checkout-form-input"
                type="text"
                name="email"
                id="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                style={{ marginBottom: "2vh" }}
                placeholder=" "
              />
              <label htmlFor="email">
                <span className="first-placeholder">Email</span>
                <span className="second-placeholder">
                  &nbsp;(We will email your receipt and link to order page)
                </span>
              </label>
            </div>

            {formik.touched.email && formik.errors.email ? (
              <span
                style={{
                  display: "block",
                  color: "red",
                  fontSize: "0.9rem",
                  margin: "-1.5vh 0 1vh 1rem",
                }}
              >
                {formik.errors.email}
              </span>
            ) : null}

            <PhoneInput
              isValid={(value: any, country: any) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
              placeholder="Phone number (Optional)"
              containerClass="phone-input-container"
              inputClass="phone-number-input"
              dropdownClass="country-code-container"
              buttonClass="country-code-button"
              searchClass="country-code-search"
              countryCodeEditable={false}
              value={phone}
              onChange={setPhone}
              preferredCountries={["gb", "us"]}
              enableSearch
              disableSearchIcon
              specialLabel=""
            />

            <div className="flex align-items-center">
              {agree ? (
                <div
                  className="box-checked-radio"
                  onClick={() => {
                    setAgree(!agree);
                  }}
                >
                  <div className="box-checked-radio-outline">
                    <div className="box-checked-radio-inner"></div>
                  </div>
                </div>
              ) : (
                <div
                  className="box-unchecked-radio"
                  onClick={() => {
                    setAgree(!agree);
                  }}
                >
                  <div className="box-unchecked-radio-outline">
                    <div className="box-unchecked-radio-inner"></div>
                  </div>
                </div>
              )}

              <div
                style={{
                  fontSize: "11px",
                  color: "#0e0e0eb5",
                  margin: "10px 5px",
                }}
              >
                You agree to our{" "}
                <a
                  href="https://www.noqgroup.com/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span style={{ color: "#ec6bf8" }}>Terms and Condition</span>
                </a>{" "}
                and{" "}
                <a
                  href="https://www.noqgroup.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span style={{ color: "#ec6bf8" }}>Privacy Policy</span>
                </a>
              </div>
            </div>

            {!agree ? (
              <div
                style={{
                  color: "red",
                  marginLeft: "1.2rem",
                  marginBottom: "0.5rem",
                  fontSize: "0.8rem",
                }}
              >
                Please agree to terms and conditions
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              {newsLetter ? (
                <div
                  className="box-checked-radio"
                  onClick={() => {
                    setNewsLetter(!newsLetter);
                  }}
                >
                  <div className="box-checked-radio-outline">
                    <div className="box-checked-radio-inner"></div>
                  </div>
                </div>
              ) : (
                <div
                  className="box-unchecked-radio"
                  onClick={() => {
                    setNewsLetter(!newsLetter);
                  }}
                >
                  <div className="box-unchecked-radio-outline">
                    <div className="box-unchecked-radio-inner"></div>
                  </div>
                </div>
              )}
              <div
                style={{
                  fontSize: "11px",
                  color: "#0e0e0eb5",
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                I do not want to receive updates about promotions, discounts,
                special offers and events from vendor
              </div>
            </div>
            <button
              className="proceed-button"
              type="submit"
              disabled={disablePayButton()}
            >
              Proceed to Pay
            </button>
          </form>
        </div>
      </div>
      {showLoader && (
        <div className="loader_layer">
          <p className="warning">
            While order is processing, please do not press/close and do not
            put this app into background. It may cause failure
          </p>
          <div className="loader_backdrop">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ReactLoading
                type={"bars"}
                color={"#7422c5"}
                height={80}
                width={80}
              />
            </div>
          </div>
        </div>
      )}  
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    basket: state.cart,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    injectOrderType: (orderType: any) => {
      dispatch(injectOrdeTypeToCart(orderType));
    },
    removeCart: () => dispatch(emptyCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
