import { updateObject } from "../actions/utility";

const storedCart = JSON.parse(localStorage.getItem("cartItems")) || {};

export const productsInitialState = {
  products: [],
  productsError: null,
  loadingProducts: true,
  basket: [],
  vendorId: null,
  cart: { ...storedCart },
};

export const addVendorId = (state, action) => {
  return updateObject(state, {
    vendorId: action.payload,
  });
};

export const getproductsStart = (state, action) => {
  return updateObject(state, {
    loadingProducts: true,
  });
};

export const getproductsSuccess = (state, action) => {
  return updateObject(state, {
    products: action.payload,
    loadingProducts: false,
    productsError: null,
  });
};

export const getproductsFail = (state, action) => {
  return updateObject(state, {
    productsError: action.payload,
    loadingProducts: false,
    products: [],
  });
};

export const addItemsToCart = (state, action) => {
  localStorage.setItem("cartItems", JSON.stringify(action.payload));
  return updateObject(state, {
    cart: action.payload,
  });
};

export const getCart = (state, action) => {
  return state;
};

export const emptyCart = (state, action) => {
  localStorage.removeItem("cartItems");
  return updateObject(state, {
    cart: {},
  });
};

export const setReload = (state, action) => {
  return updateObject(state, {
    menuReload: action.payload,
  });
};
