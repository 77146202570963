import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import getSymbolFromCurrency from "currency-symbol-map";
import Popup from "reactjs-popup";

import Orders from "./Order";
import {
  emptyCart,
  getUserCart,
  injectTheTipIntoCart,
  injectServiceChargeToCart,
  injectServiceFeeToCart,
  injectDeliveryFeeToCart
} from "../../store/actions/productActions";

import "./MyBasket.scss";
import { ReactComponent as BackArrow } from "../../assets/imgs/left-arrow.svg";
import { ReactComponent as Trash } from "../../assets/imgs/trash.svg";
import { getVAT } from "../../utils/utils";
import { CommonApiAxios } from "../../apiUtil/CommonApiAxios";

const MyBasket = (props: any) => {
  const { basket, emptyCart, getCart, injectTipToCart, injectServiceCharge, injectServiceFee, injectDeliveryFee } = props;
  const history = useHistory();
  const [tip, setTip] = useState(basket && basket.tip > 0 ? basket.tip : "");
  const [tipType, setTipType] = useState(
    basket.tipType ? basket.tipType : "fixed"
  );
  const [outOfStockError, setOutOfStockError] = useState([]);

  const currency = basket && basket.currency ? basket.currency : "gbp";

  useEffect(() => {
    getCart();
    if (!props.basket || Object.keys(props.basket).length === 0) {
      history.goBack();
    }
  }, [props.basket]);

  const calculateServiceCharge = () => {
    let serviceChargeAmount = 0;
    if (basket && (basket.serviceChargeInPercentage || basket.serviceChargeInFix)) {
      serviceChargeAmount = Math.round(((basket.subTotal * (basket.serviceChargeInPercentage / 100))+ basket.serviceChargeInFix) * 100) / 100
    }
    return serviceChargeAmount;
  };

  const calculateServiceFee = () => {
    let serviceFeeAmount = 0;
    if (basket && (basket.commissionPercentage || basket.serviceFeeFix)) {
      const vendorTotal = basket.subTotal + calculateServiceCharge() + calculateTip();
      serviceFeeAmount = Math.round(((vendorTotal * (basket.commissionPercentage / 100))+ basket.serviceFeeFix) * 100) / 100
    }
    return serviceFeeAmount;
  };

  const calculateDeliveryFee = () => {
    let deliveryFee = 0;
    if (basket && basket.onSiteDeliveryFee && basket.serviceType.length == 1 && basket.serviceType[0] == 'On-Site Delivery') {
      deliveryFee = basket.onSiteDeliveryFee;
    }
    return deliveryFee;
  }

  const calculateTip = () => {
    if (!Number(tip)) {
      return 0;
    } else if (tipType === "fixed") {
      return parseFloat(tip);
    }
    return (
      ((basket.subTotal + calculateServiceCharge()) * parseFloat(tip)) / 100
    );
  };

  const checkoutMyBasket = () => {
    const apiEndpoint = "/orders/check-item-stock-qty";
    CommonApiAxios("POST", apiEndpoint, { items: basket.items })
      ?.then((response) => {
        if (response?.data?.data?.errorArray.length > 0) {
          setOutOfStockError(response?.data?.data?.errorArray);
        } else {
          const parsedTip = parseFloat(tip);
          const serviceCharge = calculateServiceCharge();
          const serviceFee = calculateServiceFee();
          const deliveryFee = calculateDeliveryFee();
          injectServiceCharge(serviceCharge);
          injectTipToCart(parsedTip, tipType);
          injectServiceFee(serviceFee);
          injectDeliveryFee(deliveryFee);
          history.push("/checkout");
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const blockInvalidChar = (e: any) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const tipChangeHandler = (e: any) => {
    e.preventDefault();
    let tempTip = e.target.value;
    setTip(tempTip);
  };

  const serviceCharge = calculateServiceCharge();
  const tipAmount = calculateTip();
  const serviceFee = calculateServiceFee();
  const deliveryFee = calculateDeliveryFee();

  let vatArrayObject;

  if(basket.items && basket.items.length > 0){
    const getVatArray = getVAT(basket.items);
    getVatArray.sort((a,b) => a.percentage - b.percentage )
    vatArrayObject = getVatArray;
  }

  return (
    <>
      {basket && (
        <div className="my-basket">
          <div className="my-basket-nav">
            <div className="my-basket-nav-top">
              <BackArrow
                onClick={() => {
                  history.goBack();
                }}
                className="my-basket-back"
                height="30"
                width="30"
              />
              <Trash
                height="25"
                width="25"
                onClick={() => {
                  emptyCart();
                }}
              />
            </div>
            <h2 className="my-basket-nav-heading">My Basket</h2>
            <div className="my-basket-nav-sub-heading">Review your order</div>
          </div>
          <div className="my-basket-container">
            {basket.items && (
              <div className="orders">
                {basket.items.map((order: any, index: any) => (
                  <Orders
                    key={index}
                    description={order.description}
                    name={order.name}
                    price={order.subTotal}
                    in_basket={order.quantity}
                    currency={currency}
                    productQuantity={order.quantity}
                    itemVariant={order.variant ? order.variant.name : ""}
                    variantPrice={order.variant ? order.variant.price : ""}
                    toppings={order.toppings ? order.toppings : []}
                    item={order}
                    indexOfItem={index}
                  />
                ))}
              </div>
            )}
            <div className="tip">
              <input
                onKeyDown={blockInvalidChar}
                onChange={(e: any) => {
                  tipChangeHandler(e);
                }}
                type="number"
                min="0"
                name="tip"
                placeholder="Add Tip"
                className="tip-input"
                value={tip}
              />
              <div className="tip-type">
                <div
                  className={
                    tipType === "fixed"
                      ? "tip-type-rate"
                      : "tip-type-rate-selected"
                  }
                  onClick={() => {
                    setTipType("percentage");
                  }}
                >
                  %
                </div>
                <div style={{ margin: "0 10px", fontWeight: "lighter" }}>
                  Or
                </div>
                <div
                  className={
                    tipType === "percentage"
                      ? "tip-type-rate"
                      : "tip-type-rate-selected"
                  }
                  onClick={() => {
                    setTipType("fixed");
                  }}
                >
                  {getSymbolFromCurrency(currency)}
                </div>
              </div>
            </div>
            <div className="order-price-calc-container">
              <div style={{ color: "#d5cbd2" }}>
                <div className="order-price-calc">
                  <span>
                    Item total ({basket.items ? basket.items.length : 0})
                  </span>
                  <span>
                    <FormattedNumber
                      value={basket.subTotal ? basket.subTotal : 0}
                      style="currency"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                      currency={currency}
                    />
                  </span>
                </div>
                {Boolean(basket) && Boolean(serviceCharge) && (
                  <div className="order-price-calc">
                    <span>
                      Service Charge
                    </span>
                    <span>
                      <FormattedNumber
                        value={serviceCharge}
                        style="currency"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        currency={currency}
                      />
                    </span>
                  </div>
                )}
                {Boolean(Number(tip)) && (
                  <div className="order-price-calc">
                    <span>Tip</span>{" "}
                    <span>
                      {
                        <FormattedNumber
                          value={tipAmount}
                          style="currency"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          currency={currency}
                        />
                      }
                    </span>
                  </div>
                )}
                {Boolean(basket) && Boolean(serviceFee) && (
                  <div className="order-price-calc">
                    <span>
                      Platform Fee
                    </span>
                    <span>
                      <FormattedNumber
                          value={serviceFee}
                          style="currency"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          currency={currency}
                      />
                    </span>
                  </div>
                )}
                {Boolean(basket) && Boolean(deliveryFee) && (
                  <div className="order-price-calc">
                    <span>
                      Delivery Fee
                    </span>
                      <span>
                      <FormattedNumber
                          value={deliveryFee}
                          style="currency"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          currency={currency}
                      />
                    </span>
                  </div>
                )}
              </div>
              <hr />
              <div className="order-price-calc">
                <span>Total Amount</span>
                <span>
                  <FormattedNumber
                    value={
                      (basket.subTotal ? basket.subTotal : 0) +
                      serviceCharge +
                      tipAmount +
                      deliveryFee +
                      serviceFee 
                    }
                    style="currency"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    currency={currency}
                  />
                </span>
              </div>
              {vatArrayObject && vatArrayObject.length > 0 && vatArrayObject.map((vat:any, index:any) => {
                  return (
                    <div className="order-price-calc" style={{ color: "#d5cbd2" }} key={index}>
                      <span>
                        {vat.label}
                      </span>
                      <span>
                        <FormattedNumber
                          value={vat.value}
                          style="currency"
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                          currency={currency}
                        />
                      </span>
                    </div>
                  )
                })}
            </div>
            <button onClick={checkoutMyBasket} className="checkout-button">
              Check Out
            </button>
            <Popup 
            className="popup-container"
            open={outOfStockError.length > 0} 
            modal>
              {(close: any) => (
                <div style={{margin: "10px"}}>
                  <h5>
                    Sorry, the selected items are currently out of stock. Please modify the quantity or select a different item.
                  </h5> 
                  <ul>
                  {outOfStockError.map((item: any) => (
                    <><li id={item}>{item}</li></>
                  ))}
                  </ul>
                  <div className="error-list">
                  <button
                  className="popup-button"
                    onClick={() => {
                      setOutOfStockError([]);
                      close;
                    }}
                  >
                    Okay
                  </button>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    basket: state.cart,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    emptyCart: () => {
      dispatch(emptyCart());
    },
    getCart: () => {
      dispatch(getUserCart());
    },
    injectTipToCart: (tip: any, type: any) => {
      dispatch(injectTheTipIntoCart(tip, type));
    },
    injectServiceCharge: (serviceCharge: any) => {
      dispatch(injectServiceChargeToCart(serviceCharge));
    },
    injectServiceFee: (serviceFee: any) => {
      dispatch(injectServiceFeeToCart(serviceFee));
    },
    injectDeliveryFee: (deliveryFee: any) => {
      dispatch(injectDeliveryFeeToCart(deliveryFee));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBasket);
