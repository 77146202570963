import { BrowserRouter } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "react-toastify/dist/ReactToastify.css";

import BaseRouter from "./router";
import { ErrorFallback } from "./Components/ErrorComponent/ErrorFallback";
import Layouts from "./Container/Layouts";
import Constants from "./constants";

const RouterWithErrorBoundary = withErrorBoundary(BaseRouter, {
  FallbackComponent: ErrorFallback,
});

const stripePromise = loadStripe(
  Constants.stripeKey ? Constants.stripeKey : ""
);

function App(props: any) {
  return (
    <BrowserRouter>
      <Layouts>
        <Elements
          stripe={stripePromise}
          options={{
            locale: "en",
            fonts: [
              {
                family: "Cantarell",
                src: "url(https://fonts.gstatic.com/s/cantarell/v10/B50NF7ZDq37KMUvlO015jKJrPqySLQ.woff2)",
                weight: "500",
              },
            ],
          }}
        >
          <IntlProvider locale="en">
            <RouterWithErrorBoundary />
            <ToastContainer limit={1} />
          </IntlProvider>
        </Elements>
      </Layouts>
    </BrowserRouter>
  );
}

export default App;
